"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const literalPlugin = {
    name: "Literal Syntax Highlighter",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Literal],
    render: node => {
        return [
            [{
                    element: document.createTextNode("\\" + node.original)
                }],
            undefined
        ];
    }
};
exports.default = literalPlugin;
