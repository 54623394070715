import React, { useContext } from 'react';
import Shell from "./Shell";
import { UserContext } from "./contexts/UserContext";
import Spinner from './components/Spinner';
import LoginBox from './components/LoginBox';

function App() {
  const { userState } = useContext(UserContext);

  if (!userState.loaded) {
    // TODO: If the user is incognito, they won't be able to persist data locally
    // So they'll never get past this point.
    return <Spinner />
  }

  if (userState.token) {
    return <Shell />;
  }

  return <LoginBox />;
}

export default App;
