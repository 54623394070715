"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const paragraphPlugin = {
    name: "Paragraph HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Paragraph],
    render: () => {
        const p = document.createElement("p");
        p.classList.add('gmParagraph');
        return [
            [{
                    childrenContainer: true,
                    element: p,
                }],
            undefined,
        ];
    }
};
exports.default = paragraphPlugin;
