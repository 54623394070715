"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const customBlockPlugin = {
    name: "Custom Block Parser",
    pluginType: "parser",
    kind: mark_common_1.GMKind.Block,
    dependencies: [],
    charProcessor: (char, state) => {
        if (char === ":" &&
            state.contextualLinePosition === state.unattachedChars.length &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, [":"])) {
            state.nextRelevantPlugins.push(customBlockPlugin);
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        if (state.contextualLinePosition < 3) {
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        // Subsequent characters after the first 3+ colons
        if (mark_common_1.isAlphanumeric(char) &&
            state.contextualLinePosition === state.unattachedChars.length) {
            state.nextRelevantPlugins.push(customBlockPlugin);
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        // Newline
        if (mark_common_1.isLineEndingChar(char) &&
            state.contextualLinePosition === state.unattachedChars.length &&
            state.unattachedChars.length >= 4) {
            const segments = state.unattachedChars.split(":");
            const noOfColons = segments.length - 1;
            const pluginIdentifier = segments.pop();
            state.literalContext = {
                plugin: customBlockPlugin,
                closer: ":".repeat(noOfColons),
                indentContext: state.indentContext,
            };
            return {
                nextPlugin: false,
                charCaptured: true,
                addition: {
                    type: mark_common_1.GMType.Custom,
                    literalChildren: true,
                    original: {
                        opening: state.unattachedChars,
                        closing: null,
                    },
                    children: [],
                },
            };
        }
        return {
            nextPlugin: true,
            charCaptured: false,
        };
    },
    EOFProcessor: () => ({
        nextPlugin: true,
    })
};
exports.default = customBlockPlugin;
