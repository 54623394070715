"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const codeBlockPlugin = {
    name: "Code Block Parser",
    pluginType: "parser",
    kind: mark_common_1.GMKind.Block,
    dependencies: [],
    charProcessor: (char, state) => {
        if (char === "`" &&
            state.contextualLinePosition === state.unattachedChars.length &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["`"])) {
            state.nextRelevantPlugins.push(codeBlockPlugin);
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        // First character after the first 3+ backticks
        if (mark_common_1.isAlphanumeric(char) &&
            state.contextualLinePosition >= 3 &&
            state.contextualLinePosition === state.unattachedChars.length &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["`"])) {
            state.nextRelevantPlugins.push(codeBlockPlugin);
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        // Subsequent characters
        if ((mark_common_1.isAlphanumeric(char) || mark_common_1.isWhiteSpaceChar(char)) &&
            state.contextualLinePosition === state.unattachedChars.length &&
            state.unattachedChars.length >= 3) {
            state.nextRelevantPlugins = [codeBlockPlugin];
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        // Newline
        if (mark_common_1.isLineEndingChar(char) &&
            state.contextualLinePosition === state.unattachedChars.length &&
            state.unattachedChars.length >= 3) {
            const segments = state.unattachedChars.split("`");
            const noOfBackticks = segments.length - 1;
            state.literalContext = {
                plugin: codeBlockPlugin,
                closer: "`".repeat(noOfBackticks),
                indentContext: state.indentContext,
            };
            return {
                nextPlugin: false,
                charCaptured: true,
                addition: {
                    type: mark_common_1.GMType.CodeBlock,
                    literalChildren: true,
                    original: {
                        opening: state.unattachedChars,
                        closing: null,
                    },
                    children: [],
                },
            };
        }
        return {
            nextPlugin: true,
            charCaptured: false,
        };
    },
    EOFProcessor: () => ({
        nextPlugin: true,
    })
};
exports.default = codeBlockPlugin;
