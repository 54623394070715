"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const latexInlinePlugin = {
    name: "LaTeX Inline Parser",
    pluginType: "parser",
    kind: mark_common_1.GMKind.Inline,
    dependencies: [],
    charProcessor: (char, state, list) => {
        if (char === "$") {
            state.nextRelevantPlugins.push(latexInlinePlugin);
            return {
                nextPlugin: true,
                charCaptured: false,
                newContext: false,
            };
        }
        if (state.unattachedChars.length > 0 &&
            (0, mark_common_1.stringOnlyContainsChars)(state.unattachedChars, ["$"])) {
            list.push({
                type: mark_common_1.GMType.LatexInline,
                literalChildren: true,
                original: {
                    opening: state.unattachedChars,
                    closing: null,
                },
                children: [],
            });
            state.literalContext = {
                plugin: latexInlinePlugin,
                closer: state.unattachedChars,
            };
            state.unattachedChars = "";
            return {
                nextPlugin: false,
                charCaptured: false,
                newContext: false,
            };
        }
        return {
            nextPlugin: true,
            charCaptured: false,
            newContext: false,
        };
    },
    closeProcessor: (state, list, proper) => {
        var _a;
        let content;
        const rawContent = state.unattachedChars
            .split(((_a = state.literalContext) === null || _a === void 0 ? void 0 : _a.closer) || "")
            .join("");
        if (rawContent.length > 2 &&
            rawContent.startsWith(" ") &&
            rawContent.endsWith(" ")) {
            content = rawContent.slice(1, rawContent.length - 1);
        }
        else {
            content = rawContent;
        }
        if (proper) {
            list[list.length - 1].original.closing =
                state.literalContext.closer;
        }
        if (rawContent !== "") {
            list[list.length - 1].children.push({
                type: mark_common_1.GMType.Text,
                original: rawContent,
                children: [],
            });
        }
    },
    openingDetector: char => char === "$",
    EOFProcessor: (state, list) => {
        if ((0, mark_common_1.stringOnlyContainsChars)(state.unattachedChars, ["$"])) {
            list.push({
                type: mark_common_1.GMType.CodeSpan,
                literalChildren: true,
                original: {
                    opening: state.unattachedChars,
                    closing: null,
                },
                children: [],
            });
            return false;
        }
        return true;
    },
};
exports.default = latexInlinePlugin;
