"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const types_1 = require("../../../types");
const mark_common_1 = require("@gojipress/mark-common");
const blankLinePlugin = {
    name: "Blank Line Parser",
    pluginType: "parser",
    kind: types_1.GMKind.Block,
    dependencies: [],
    charProcessor: (char, state) => {
        if (mark_common_1.isLineEndingChar(char) &&
            state.contextualLinePosition === state.unattachedChars.length &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, mark_common_1.whitespaces)) {
            if (state.indentContext === 0) {
                state.availableIndentContext = [];
            }
            return {
                nextPlugin: false,
                charCaptured: true,
                addition: {
                    type: types_1.GMType.Blank,
                    original: state.unattachedChars,
                    children: [],
                },
            };
        }
        if (mark_common_1.isWhiteSpaceChar(char) &&
            state.contextualLinePosition === state.unattachedChars.length &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, mark_common_1.whitespaces)) {
            state.nextRelevantPlugins.push(blankLinePlugin);
        }
        return {
            nextPlugin: true,
            charCaptured: false,
        };
    },
    EOFProcessor: (state) => {
        if (state.contextualLinePosition === state.unattachedChars.length &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, mark_common_1.whitespaces)) {
            return {
                nextPlugin: false,
                addition: {
                    type: types_1.GMType.Blank,
                    original: state.unattachedChars,
                    children: [],
                }
            };
        }
        return {
            nextPlugin: true,
        };
    },
};
exports.default = blankLinePlugin;
