"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const thematicBreakPlugin = {
    name: "Thematic Break HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.ThematicBreak],
    render: () => {
        const hr = document.createElement("hr");
        hr.classList.add('gmThematicBreak');
        return [
            [{
                    element: hr
                }],
            undefined
        ];
    }
};
exports.default = thematicBreakPlugin;
