import React, { useContext } from "react";
import TabsBar from "./TabsBar";
import { TabsContext } from "../contexts/TabsContext";
import Editor from "./Editor";
import styles from "./EditorsPanel.module.scss";


function EditorsPanel({i}: {i: number}) {
  const { activePanel, setActivePanel } = useContext(TabsContext);

  return (
    <div
      className={`${styles.editorsPanel} ${activePanel === i ? styles.active : null}`} 
      onClick={() => setActivePanel(i)} >
      <TabsBar i={i} />
      <Editor i={i} />
    </div>
  );
}

export default EditorsPanel;
