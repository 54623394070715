import React from "react";
import { ISearchResultItem, Post } from '../../common/entities'
import styles from './PostSearcherResultsItem.module.scss';

function PostSearcherResultsItem({entity, onSelect}: {entity: ISearchResultItem, onSelect: (name: Post) => void;}) {
  const post: Post = {
    UUID: entity.Self.UUID,
    Title: entity.Self.Name,
    Slug: "", // Slug is irrelevant
    Privacy: entity.Self.Privacy,
    Owner: entity.Self.Owner,
    Goji: false, // Goji is irrelevant
  }
  return (
    <div
      className={`${styles.container}`}
      onClick={() => onSelect(post)}
    >
      {entity.Self.Name}
    </div>
  )
}

export default PostSearcherResultsItem;
