import axios from "axios";

const single = (token: string, uuid: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/maps/${uuid}`, {
      params: {
        d: 3,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to get map");
      }
      if (res.status === 404) {
        throw new Error("Entity not found");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    });
};

const world = (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/maps/world`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to get world map");
      }
      if (res.status === 404) {
        throw new Error("Entity not found");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    });
};

export {
  single,
  world,
}