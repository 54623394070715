import React, { useContext } from 'react';
import slug from "slug";

import { Post, Concept, PostWithContext, ConceptWithContext } from "../../common/entities";
import { EntitiesContext, Entity } from "../../contexts/EntitiesContext";

import NameWithAliasInput from "../NameWithAliasInput"
import ConceptsSelector from "../ConceptsSelector"
import PostsSelector from "../PostsSelector"
import PrivacySelector from "../PrivacySelector"
import UploadLocalEntityButton from "../UploadLocalEntityButton"

import styles from './InfoPaneEdit.module.scss';

function InfoPaneEdit({ entity }: { entity: Entity }) {
  const { updateLocalEntity } = useContext(EntitiesContext);

  const updateEntityNames = (names: string[]) => {
    if (entity && entity.entityType === "Concept") {
      updateLocalEntity({
        ...(entity.entity as ConceptWithContext),
        Self: {
          ...entity.entity.Self,
          Names: names,
        },
      })
    }
  }

  const updateEntitySlug = (slug: string) => {
    if (entity && entity.entityType === "Concept") {
      updateLocalEntity({
        ...(entity.entity as ConceptWithContext),
        Self: {
          ...(entity.entity as ConceptWithContext).Self,
          Slug: slug,
        },
      })
    }
    if (entity && entity.entityType === "Post") {
      updateLocalEntity({
        ...(entity.entity as PostWithContext),
        Self: {
          ...(entity.entity as PostWithContext).Self,
          Slug: slug,
        },
      })
    }
  }

  const handleTitleBlur = (name: string) => {
    if (entity.entity.Self.Slug === "") {
      updateEntitySlug(slug(name))
    }
  }

  const updateEntityTitle = (title: string) => {
    if (entity && entity.entityType === "Post") {
      updateLocalEntity({
        ...(entity.entity as PostWithContext),
        Self: {
          ...(entity.entity as PostWithContext).Self,
          Title: title,
        },
      })
    }
  }
  const updateEntityCovers = (covers: Concept[]) => {
    if (entity && entity.entityType === "Post") {
      updateLocalEntity({
        ...(entity.entity as PostWithContext),
        Covers: covers,
      })
    }
  }

  const updateEntityParents = (parents: Concept[] | Post[]) => {
    if (entity && entity.entityType === "Concept") {
      updateLocalEntity({
        ...(entity.entity as ConceptWithContext),
        Parents: parents,
      } as ConceptWithContext)
    }
    if (entity && entity.entityType === "Post") {
      updateLocalEntity({
        ...(entity.entity as PostWithContext),
        Parents: parents,
      } as PostWithContext)
    }
  }
  
  const updateEntityPrivacy = (privacy?: "private" | "public") => {
    if (entity && entity.entityType === "Concept") {
      updateLocalEntity({
        ...(entity.entity as ConceptWithContext),
        Self: {
          ...(entity.entity as ConceptWithContext).Self,
          Privacy: privacy,
        },
      })
    }
  }

  return (
    <React.Fragment>
      { entity.entityType === "Concept" ? (
        <React.Fragment>
          <NameWithAliasInput
            names={(entity.entity as ConceptWithContext).Self.Names}
            onChange={updateEntityNames}
            onBlur={handleTitleBlur}
          />
          <ConceptsSelector concepts={(entity.entity as ConceptWithContext).Parents} label="Parent" onChange={updateEntityParents} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <label htmlFor="title">Title</label>
          <input
            id="title"
            name="title"
            required
            value={(entity.entity as PostWithContext).Self.Title}
            onChange={(e) => updateEntityTitle(e.currentTarget.value)}
            onBlur={() => handleTitleBlur((entity.entity as PostWithContext).Self.Title)}
          />
          <ConceptsSelector concepts={(entity.entity as PostWithContext).Covers} label="Cover" onChange={updateEntityCovers} />
          <PostsSelector posts={(entity.entity as PostWithContext).Parents} label="Parent" onChange={updateEntityParents} />
        </React.Fragment>
      )}

        <label htmlFor="slug">Slug</label>
        <input
          id="slug"
          placeholder="Slug"
          type="text"
          value={(entity.entity as ConceptWithContext).Self.Slug}
          onChange={(e) => updateEntitySlug(e.currentTarget.value)}
          pattern="^[a-z0-9]+(?:-[a-z0-9]+)*$"
        />
        <PrivacySelector privacy={(entity.entity as ConceptWithContext).Self.Privacy} onChange={updateEntityPrivacy} />
        { entity.local ? <UploadLocalEntityButton uuid={entity.entity.Self.UUID} /> : null }
    </React.Fragment>
  );
}

export default InfoPaneEdit;
