"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const paragraphPlugin = {
    name: "Paragraph Syntax Highlighter",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Paragraph],
    render: () => {
        const span = document.createElement("span");
        span.classList.add('gmParagraph');
        return [
            [{
                    childrenContainer: true,
                    element: span,
                }, {
                    element: document.createElement("br")
                }],
            undefined
        ];
    }
};
exports.default = paragraphPlugin;
