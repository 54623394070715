"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const types_1 = require("../../types");
const textPlugin = {
    name: "Text Parser",
    pluginType: "parser",
    kind: types_1.GMKind.Inline,
    dependencies: [],
    charProcessor: (char, state, list) => {
        state.nextRelevantPlugins.push(textPlugin);
        return {
            nextPlugin: true,
            charCaptured: false,
            newContext: false,
        };
    },
    closeProcessor: (state, tree) => {
        // Make sure Text is not empty (whitespace is not empty)
        if (state.unattachedChars.length > 0) {
            tree.push({
                type: types_1.GMType.Text,
                original: state.unattachedChars,
                children: [],
            });
        }
    },
    EOFProcessor: (state, list) => {
        // Make sure Text is not empty (whitespace is not empty)
        if (state.unattachedChars.length > 0) {
            list.push({
                type: types_1.GMType.Text,
                original: state.unattachedChars,
                children: [],
            });
        }
        return false;
    },
    openingDetector: () => false, // Text is a fallback plugin, should never return true
};
exports.default = textPlugin;
