"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const headingPlugin = {
    name: "Heading Syntax Highlighter",
    pluginType: "renderer",
    types: [
        mark_common_1.GMType.Heading1,
        mark_common_1.GMType.Heading2,
        mark_common_1.GMType.Heading3,
        mark_common_1.GMType.Heading4,
        mark_common_1.GMType.Heading5,
        mark_common_1.GMType.Heading6,
    ],
    render: (node) => {
        const hashesSpan = document.createElement("span");
        hashesSpan.classList.add(`gmSHHeadingHashes`);
        hashesSpan.classList.add(`gm${node.type}Hashes`);
        switch (node.type) {
            case mark_common_1.GMType.Heading1:
                hashesSpan.appendChild(document.createTextNode("# "));
                break;
            case mark_common_1.GMType.Heading2:
                hashesSpan.appendChild(document.createTextNode("## "));
                break;
            case mark_common_1.GMType.Heading3:
                hashesSpan.appendChild(document.createTextNode("### "));
                break;
            case mark_common_1.GMType.Heading4:
                hashesSpan.appendChild(document.createTextNode("#### "));
                break;
            case mark_common_1.GMType.Heading5:
                hashesSpan.appendChild(document.createTextNode("##### "));
                break;
            case mark_common_1.GMType.Heading6:
                hashesSpan.appendChild(document.createTextNode("###### "));
                break;
        }
        const contentSpan = document.createElement("span");
        contentSpan.classList.add(`gmSHHeadingContent`);
        contentSpan.classList.add(`gmSH${node.type}Content`);
        return [
            [{
                    element: hashesSpan,
                }, {
                    element: contentSpan,
                    childrenContainer: true,
                }, {
                    element: document.createElement("br"),
                }],
            undefined,
        ];
    },
};
exports.default = headingPlugin;
