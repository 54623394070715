import React from "react";
import { UseQueryResult } from "react-query";

import { ISearchResultItem, Concept } from '../../common/entities'
import Spinner from '../Spinner';
import ConceptSearcherResultsItem from './ConceptSearcherResultsItem';
import styles from './ConceptSearcherResults.module.scss';

function ConceptSearcherResults({parents, results, onSelect}: {parents: Concept[]; results: UseQueryResult; onSelect: (name: Concept) => void; }) {
  const { status, data, error } = results;

  let inside

  if (status === "loading") {
    inside = <div className={styles.spinnerContainer}><Spinner /></div>;
  }

  if (status === "error") {
    inside = error instanceof Error ? <span className={styles.error}>Error: {error.message}</span> : null;
  }
  if (status === "success" && Array.isArray(data)) {
    const filteredData = Array.isArray(parents) ? data.filter((result: ISearchResultItem) => !parents.map(parent => parent.UUID).includes(result.Self.UUID)) : data;
    if (filteredData.length > 0) {
      inside = filteredData.map((result: ISearchResultItem) => <ConceptSearcherResultsItem entity={result} key={result.Self.UUID} onSelect={onSelect} />)
    } else {
      inside = <span className={styles.noResults}>No results</span>;
    }
  }
  return (
    <div className={styles.container}>
      {inside}
    </div>
  )
}

export default ConceptSearcherResults;
