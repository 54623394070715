import React, { createContext, FunctionComponent, useEffect, useState } from "react";
import { openDB } from 'idb';

type UserState = {
    loaded: boolean
    email: string
    id: string
    token: string
}

const defaultUserState: UserState = {
    loaded: false,
    email: "",
    id: "",
    token: "",
}

const UserContext = createContext({
    userState: defaultUserState,
    logIn: (email: string, token: string) => {},
    logOut: () => {},
});

const UserProvider: FunctionComponent = ({ children }) => {

    const [userState, setUserState] = useState(defaultUserState);
    
    const loadUserStateFromIndexedDB = async () => {
        const db = await openDB("goji", 1, {
            upgrade(db) {
                db.createObjectStore('users', { keyPath: 'email' });
            }
        });
        const tx = db.transaction('users', 'readonly');
        const value = await tx.store.getAll();
        const newUserState = value.length > 0 ? value[0] : userState
        setUserState({
            ...newUserState,
            loaded: true,
        })
    }

    const persistUserStateToIndexedDB = async (userState: UserState) => {
        const db = await openDB("goji", 1, {
            upgrade(db) {
                db.createObjectStore('users', { keyPath: 'email' });
            }
        });
        const tx = db.transaction('users', 'readwrite');
        await Promise.all([
            tx.store.put({
                email: userState.email,
                id: userState.id,
                token: userState.token,
            }),
            tx.done
        ]);
    }

    const defaultUserContext = {
        userState,
        logIn: (email: string, token: string) => {
          const id = JSON.parse(atob(token.split(".")[1])).sub;
            setUserState({email, id, token, loaded: true})
            persistUserStateToIndexedDB({email, id, token, loaded: true})
        },
        logOut: () => {
            setUserState(defaultUserState)
            persistUserStateToIndexedDB(defaultUserState)
        },
      }
    useEffect(() => {
        loadUserStateFromIndexedDB()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <UserContext.Provider value={defaultUserContext}>
            {children}
        </UserContext.Provider>
    )
}

export {
    UserProvider as default,
    UserContext,
};