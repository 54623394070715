import React, { useContext } from "react";
import SplitPane from "react-split-pane";
import { TabsContext } from "../contexts/TabsContext";
import EditorsPanel from "./EditorsPanel";

function EditorsGroup() {
  const { panels } = useContext(TabsContext);
  if (panels.length === 0) {
    return null;
  }
  if (panels.length === 1) {
    <EditorsPanel i={0} />
  }
  return (
    <SplitPane split="vertical" defaultSize={`${100 / panels.length}%`} >
      {panels.map((panel, i) => <EditorsPanel i={i} key={i} />)}
    </SplitPane>
  );
}

export default EditorsGroup;
