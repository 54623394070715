"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMultiineBlock = exports.GMType = exports.GMBlockType = exports.GMContainerBlockType = exports.GMLeafBlockType = exports.GMInlineType = exports.GMKind = void 0;
var GMKind;
(function (GMKind) {
    GMKind["Block"] = "Block";
    GMKind["Inline"] = "Inline";
})(GMKind || (GMKind = {}));
exports.GMKind = GMKind;
var GMNullType;
(function (GMNullType) {
    GMNullType["Null"] = "Null";
})(GMNullType || (GMNullType = {}));
var GMLiteralType;
(function (GMLiteralType) {
    GMLiteralType["Literal"] = "Literal";
})(GMLiteralType || (GMLiteralType = {}));
var GMRootType;
(function (GMRootType) {
    GMRootType["Root"] = "Root";
})(GMRootType || (GMRootType = {}));
var GMCustomType;
(function (GMCustomType) {
    GMCustomType["Custom"] = "Custom";
})(GMCustomType || (GMCustomType = {}));
var GMInlineType;
(function (GMInlineType) {
    GMInlineType["CodeSpan"] = "CodeSpan";
    GMInlineType["Emphasis"] = "Emphasis";
    GMInlineType["Strong"] = "Strong";
    GMInlineType["ExternalLink"] = "ExternalLink";
    GMInlineType["InternalLink"] = "InternalLink";
    GMInlineType["Image"] = "Image";
    GMInlineType["Iframe"] = "Iframe";
    GMInlineType["Video"] = "Video";
    GMInlineType["Text"] = "Text";
    GMInlineType["Attention"] = "Attention";
    GMInlineType["AttentionSpan"] = "AttentionSpan";
})(GMInlineType || (GMInlineType = {}));
exports.GMInlineType = GMInlineType;
var GMLeafBlockType;
(function (GMLeafBlockType) {
    GMLeafBlockType["ThematicBreak"] = "ThematicBreak";
    GMLeafBlockType["Heading1"] = "Heading1";
    GMLeafBlockType["Heading2"] = "Heading2";
    GMLeafBlockType["Heading3"] = "Heading3";
    GMLeafBlockType["Heading4"] = "Heading4";
    GMLeafBlockType["Heading5"] = "Heading5";
    GMLeafBlockType["Heading6"] = "Heading6";
    GMLeafBlockType["CodeBlock"] = "CodeBlock";
    GMLeafBlockType["Blank"] = "Blank";
    GMLeafBlockType["Paragraph"] = "Paragraph";
})(GMLeafBlockType || (GMLeafBlockType = {}));
exports.GMLeafBlockType = GMLeafBlockType;
var GMContainerBlockType;
(function (GMContainerBlockType) {
    GMContainerBlockType["UnorderedListItem"] = "UnorderedListItem";
})(GMContainerBlockType || (GMContainerBlockType = {}));
exports.GMContainerBlockType = GMContainerBlockType;
const GMBlockType = Object.assign(Object.assign({}, GMLeafBlockType), GMContainerBlockType);
exports.GMBlockType = GMBlockType;
const GMType = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, GMInlineType), GMBlockType), GMCustomType), GMLiteralType), GMRootType), GMNullType);
exports.GMType = GMType;
function isMultiineBlock(node) {
    return Object.values(Object.assign(Object.assign({}, GMBlockType), GMCustomType)).some(t => t === node.type);
}
exports.isMultiineBlock = isMultiineBlock;
