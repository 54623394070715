import React, { useContext } from 'react';
import { v4 as uuidv4 } from "uuid";

import { UserContext } from '../../contexts/UserContext';
import { EntitiesContext } from "../../contexts/EntitiesContext";
import { Tab, TabsContext } from "../../contexts/TabsContext";

import styles from './index.module.scss';

function AddTabButton() {
  const { userState } = useContext(UserContext);
  const { createLocalEntity } = useContext(EntitiesContext);
  const { addTab } = useContext(TabsContext);

  const createConcept = () => {
    const uuid = uuidv4()
    createLocalEntity("Concept", uuid)
    const tab: Tab = {
      EntityType: "Concept",
      Owner: userState.id,
      Privacy: "private",
      Name: "",
      UUID: uuid,
      active: true,
    }
    addTab(tab);
  }

  const createPost = () => {
    const uuid = uuidv4()
    createLocalEntity("Post", uuid)
    const tab: Tab = {
      EntityType: "Post",
      Owner: userState.id,
      Privacy: "private",
      Name: "",
      UUID: uuid,
      active: true,
    }
    addTab(tab);
  }

  return (
    <div className={styles.container}>
      <div className={styles.contextMenuContainer}>
        <div className={styles.contextMenu}>
          <div className={styles.contextMenuItem} onClick={() => createConcept()}>Concept</div>
          <div className={styles.contextMenuItem} onClick={() => createLocalEntity("Post")}>Goji</div>
          <div className={styles.contextMenuItem} onClick={() => createPost()}>Post</div>
          <div className={styles.contextMenuItem}>Series</div>
          <div className={styles.contextMenuItem}>Collection</div>
        </div>
      </div>
      <div className={styles.button}></div>
    </div>
  );
}

export default AddTabButton;
