import React from 'react';
import Select from 'react-select'
import './PrivacySelector.scss';

const options = [
  { value: 'private', label: 'Private' },
  { value: 'public', label: 'Public' },
  { value: undefined, label: 'World' }
]

function PrivacySelector({ privacy, onChange }: { privacy?: string, onChange: (privacy?: "private" | "public") => void }) {
  return (
    <div className="privacy-selector">
      <label>Privacy</label>
      <Select
        value={options.find(({value}) => value === privacy)}
        isClearable={false}
        isSearchable={false}
        label="Privacy"
        options={options}
        onChange={(option) => onChange(option?.value as "public" | "private" || undefined)}
        className="privacySelector"
        classNamePrefix="privacySelector"
      />
    </div>
  );
}

export default PrivacySelector;
