"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const blankLinePlugin = {
    name: "Blank Line Syntax Highlighter",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Blank],
    render: node => {
        const elements = [{
                element: document.createElement("br")
            }];
        if (typeof node.original === 'string'
        // && node.original.length !== 0 // Render it even if it's blank
        ) {
            const span = document.createElement("span");
            span.classList.add('gmBlankLine');
            const text = document.createTextNode(node.original);
            span.appendChild(text);
            elements.unshift({
                element: span,
            });
        }
        return [elements, undefined];
    }
};
exports.default = blankLinePlugin;
