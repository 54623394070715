"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const iframePlugin = {
    name: "Iframe HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Iframe],
    render: node => {
        const iframe = document.createElement("iframe");
        iframe.classList.add('gmIframe');
        if (node.children.length > 0) {
            const content = node.children[0].original;
            const [src, ...title] = content.split(" ");
            iframe.setAttribute("src", src);
            iframe.setAttribute("title", title.join(" "));
            return [
                [{
                        element: iframe,
                    }],
                undefined,
            ];
        }
        return [[], undefined];
    }
};
exports.default = iframePlugin;
