"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const unorderedListItemPlugin = {
    name: "Unordered List Item HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.UnorderedListItem],
    reuseWrapper: (prevNode) => (prevNode === null || prevNode === void 0 ? void 0 : prevNode.type) === mark_common_1.GMType.UnorderedListItem,
    render: () => {
        const wrapper = document.createElement("ul");
        wrapper.classList.add('gmUnorderedList');
        const li = document.createElement("li");
        li.classList.add('gmUnorderedListItem');
        return [
            [{
                    childrenContainer: true,
                    element: li,
                }],
            wrapper
        ];
    },
};
exports.default = unorderedListItemPlugin;
