import React, { useState, useContext } from 'react';
import ReactModal from 'react-modal';

import { ConceptWithContext, PostWithContext } from "../../common/entities"
import { Entity, EntitiesContext } from "../../contexts/EntitiesContext";
import { Tab, TabsContext } from "../../contexts/TabsContext";
import ConceptMap from "../ConceptMap";
import styles from './InfoPanePreview.module.scss';

ReactModal.setAppElement('#root');

function InfoPanePreview({ entity }: { entity: Entity }) {
  const [ open, setOpen ] = useState(false);
  const { downloadRemoteEntity } = useContext(EntitiesContext);
  const { addTab } = useContext(TabsContext);

  const addConceptTab = (uuid: string, name: string, owner: string | undefined, privacy: "public" | "private" | undefined) => {
    downloadRemoteEntity("Concept", uuid)
    const tab: Tab = {
      EntityType: "Concept",
      Owner: owner || "",
      Privacy: privacy,
      Name: name,
      UUID: uuid,
      active: true,
    }
    addTab(tab);
  }

  const addPostTab = (uuid: string, name: string, owner: string | undefined, privacy: "public" | "private" | undefined) => {
    downloadRemoteEntity("Post", uuid)
    const tab: Tab = {
      EntityType: "Post",
      Owner: owner || "",
      Privacy: privacy,
      Name: name,
      UUID: uuid,
      active: true,
    }
    addTab(tab);
  }
  return (
    <div className={styles.container}>
      { entity.local ? null : (
      <div
        className={styles.minimap}
        onClick={() => setOpen(open => !open)}
      >
        <ConceptMap width={120} height={120} label={false} uuid={entity.entity.Self.UUID} />
      </div>
      )}
      <ReactModal
        isOpen={open}
        style={{ overlay: {}, content: {} }}
        overlayClassName={styles.overlay}
        className={styles.content}
        onRequestClose={() => setOpen(open => !open)}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        bodyOpenClassName={null}
      >
        <ConceptMap uuid={entity.entity.Self.UUID} />
      </ReactModal>

      { entity.entityType === "Concept" ? (
        <React.Fragment>
          <div className={styles.label}>Name</div>
          <div>{(entity.entity as ConceptWithContext).Self.Names[0]}</div>
          <div className={styles.label}>Alias(es)</div>
          {(entity.entity as ConceptWithContext).Self.Names.slice(1).map(alias => (
            <div key={alias}>{alias}</div>
          ))}
          {(entity.entity as ConceptWithContext).Self.Names.slice(1).length === 0 ? (<div className={styles.none}>None</div>) : null }
          <div className={styles.label}>Parents</div>
          {(entity.entity as ConceptWithContext).Parents ? (entity.entity as ConceptWithContext).Parents.map(parent => (
            <div key={parent.UUID}><div className={styles.newTabIcon} onClick={() => addConceptTab(parent.UUID, parent.Names[0], parent.Owner, parent.Privacy)}></div> {parent.Names[0]}</div>
          )) : (<div className={styles.none}>None</div>)}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={styles.label}>Title</div>
          <div>{(entity.entity as PostWithContext).Self.Title}</div>

          <div className={styles.label}>Concept(s) Covered</div>
          {(entity.entity as PostWithContext).Covers ? (entity.entity as PostWithContext).Covers.map(concept => (
            <div key={concept.UUID}><div className={styles.newTabIcon} onClick={() => addConceptTab(concept.UUID, concept.Names[0], concept.Owner, concept.Privacy)}></div> {concept.Names[0]}</div>
          )) : null}
          <div className={styles.label}>Pre-requisite(s)</div>
          {(entity.entity as PostWithContext).Parents ? (entity.entity as PostWithContext).Parents.map(prereq => (
            <div key={prereq.UUID}><div className={styles.newTabIcon} onClick={() => addPostTab(prereq.UUID, prereq.Title, prereq.Owner, prereq.Privacy)}></div> {prereq.Title}</div>
          )) : null}
        </React.Fragment>
      )}
      <div className={styles.label}>Link</div>
      <div>{entity.entity.Self.Slug || (<div className={styles.none}>None</div>)}</div>
      <div className={styles.label}>Privacy</div>
      <div>{entity.entity.Self.Privacy}</div>
    </div>
  );
}

export default InfoPanePreview;
