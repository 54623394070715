import React, { useContext, useState, useRef, useEffect, useCallback, ReactEventHandler } from "react";
import ReactDOMServer from 'react-dom/server';
import SplitPane from "react-split-pane";

import GojiMark from '@gojipress/mark-core';

import { TabsContext } from "../../contexts/TabsContext";
import { EntitiesContext } from "../../contexts/EntitiesContext";
import { PostState, PostWithContext, ConceptWithContext, ConceptState } from "../../common/entities";

import Spinner from "../Spinner";
import InfoPanePreview from "./InfoPanePreview";
import InfoPaneEdit from "./InfoPaneEdit";
import styles from "./index.module.scss";
import "./index.scss";

const syntaxHighlighter = new GojiMark()
GojiMark.defaults.plugins.renderers.syntaxHighlighting.forEach((plugin) => syntaxHighlighter.use(plugin));
syntaxHighlighter.init();

const reactSyntaxHighlighter = new GojiMark()
GojiMark.defaults.plugins.renderers.reactSyntaxHighlighting.forEach((plugin) => reactSyntaxHighlighter.use(plugin));
reactSyntaxHighlighter.init();

const htmlRenderer = new GojiMark()
GojiMark.defaults.plugins.renderers.html.forEach((plugin) => htmlRenderer.use(plugin));
htmlRenderer.init();

const complete = Symbol('complete')

function getCharacterCount(nodes: NodeList) {
  let count = 0;
  for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent) {
          count += node.textContent.length
        }
      }
      else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.nodeName === "BR") {
              count += 1
          }
          else {
              count += getCharacterCount(node.childNodes)
          }
      }
      // Ignore the rest of the node types
  }
  return count;
}

function getCaretPosition(target: HTMLParagraphElement) {
  const selection = window.getSelection();
  if (!selection) {
    return null;
  }
  const selectionRange = selection.getRangeAt(0);
  const range = new Range();
  range.selectNodeContents(target)
  range.setEnd(selectionRange.endContainer, selectionRange.endOffset);
  let rangeOffset = getCharacterCount(range.cloneContents().childNodes);
  return rangeOffset
}

function setCaretInNode(node: Node | null, offset: number) {
  if (!node) {
    return
  }
  const range = new Range();
  range.setStart(node, offset);
  range.collapse(true);
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

function setCaretPosition(target: Node | null, offset: number | null): number | typeof complete | undefined {
  if (!target || !offset) {
    return
  }
  let remainingCharacterOffset = offset;
  let nodeOffset = 0;
  const childNodes = target.childNodes;
  for (let i = 0; i < childNodes.length; i++) {
      const node = childNodes[i]
      if (node.nodeType === Node.TEXT_NODE) {
          if (node.textContent) {
            if (node.textContent.length >= remainingCharacterOffset) {
              setCaretInNode(node, remainingCharacterOffset)
              return complete;
          } else {
              remainingCharacterOffset = remainingCharacterOffset - node.textContent.length;
          }
        }
      } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === "BR") {
          if (remainingCharacterOffset === 1) {
              if (node.nextSibling && node.nextSibling.nodeType === Node.TEXT_NODE) {
                  setCaretInNode(node.nextSibling, 0)
              } else {
                  // +1 because you want it at the start of the next node instead of the current node
                  setCaretInNode(node.parentNode, nodeOffset + 1)
              }
              return complete;
          } else {
              remainingCharacterOffset = remainingCharacterOffset - 1;
          }
      } else {
          // Recursively loop through non-text nodes
          const recursiveResult = setCaretPosition(node, remainingCharacterOffset)
          if (recursiveResult === complete) {
              return complete
          }
          if (recursiveResult !== undefined) {
            remainingCharacterOffset = recursiveResult
          }
      }
      nodeOffset++;
  }
  return remainingCharacterOffset;
}

function Editor({i}: {i: number}) {

  const [ caret, setCaret ] = useState(null as number | null);
  const [ preview, setPreview ] = useState(false);
  const [ sourceScroll, setSourceScroll ] = useState(0);
  const sourceContainer = useRef<HTMLDivElement>(null)
  const editor = useRef<HTMLParagraphElement>(null)
  const { panels } = useContext(TabsContext);
  const { entities, updateLocalEntity, uploadLocalEntity } = useContext(EntitiesContext);

  const activeTab = panels[i].find(tab => tab.active)
  const entity = activeTab ? entities[activeTab.UUID] : null;


  const handleUserKeyPress = useCallback((event: globalThis.KeyboardEvent) => {
    const { key, ctrlKey } = event;
    if (ctrlKey && (key === "s" || key === "S")){
      event.preventDefault();
      uploadLocalEntity(activeTab?.UUID)
    }
    if (ctrlKey && (key === "p" || key === "P")){
      event.preventDefault();
      setPreview(prevState => !prevState)
    }
  }, [uploadLocalEntity, activeTab?.UUID]);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    setCaretPosition(editor.current, caret)
  }, [entity])

  const updateEntityBody = (e: React.FormEvent<HTMLParagraphElement>) => {
    e.preventDefault();
    setCaret(getCaretPosition(e.currentTarget));
    const text = e.currentTarget.innerText
    if (text && entity && entity.entityType === "Concept") {
      updateLocalEntity({
        ...(entity.entity as ConceptWithContext),
        States: (entity.entity as ConceptWithContext).States.map(state => {
          if (state.UUID === entity.entity.CurrentState) {
            return {
              ...state,
              Definition: text,
              LastModifiedAt: Date.now(),
            }
          }
          return state
        }),
      })
    }
    if (text && entity && entity.entityType === "Post") {
      updateLocalEntity({
        ...(entity.entity as PostWithContext),
        States: (entity.entity as PostWithContext).States.map(state => {
          if (state.UUID === entity.entity.CurrentState) {
            return {
              ...state,
              Content: text,
              LastModifiedAt: Date.now(),
            }
          }
          return state
        }),
      })
    }
  }

  const sourceHtmlContents = (() => {
    let content: string;
    if (entity && entity.entityType === "Concept") {
      content = (entity.entity.States as ConceptState[]).find((state: ConceptState) => state.UUID === entity.entity.CurrentState)?.Definition || ""
    }
    else if (entity && entity.entityType === "Post") {
      content = (entity.entity.States as PostState[]).find((state: PostState) => state.UUID === entity.entity.CurrentState)?.Content || ""
    }
    else {
      content = ""
    }
    let el = document.createElement("div");
    const fragment = syntaxHighlighter.convert(content);
    el.appendChild(fragment);
    return el.innerHTML
  })()

  useEffect(() => {
    if (sourceContainer.current) {
      sourceContainer.current!.scrollTop = sourceScroll
    }
  }, [sourceHtmlContents])

  const handleSourceScroll = () => {
    if (sourceContainer.current) {
      setSourceScroll(sourceContainer.current!.scrollTop)
    }
  }

  // const SourceReactContents = (() => {
  //   let content: string;
  //   if (entity.entityType === "Concept") {
  //     content = (entity.entity.States as ConceptState[]).find((state: ConceptState) => state.UUID === entity.entity.CurrentState)?.Definition || ""
  //   }
  //   else if (entity.entityType === "Post") {
  //     content = (entity.entity.States as PostState[]).find((state: PostState) => state.UUID === entity.entity.CurrentState)?.Content || ""
  //   }
  //   else {
  //     content = ""
  //   }
  //   console.log(content)
  //   return reactSyntaxHighlighter.convertToReact(content);
  // })()

  const previewHtmlContents = (() => {
    let content: string;
    if (entity && entity.entityType === "Concept") {
      content = (entity.entity.States as ConceptState[]).find((state: ConceptState) => state.UUID === entity.entity.CurrentState)?.Definition || ""
    }
    else if (entity && entity.entityType === "Post") {
      content = (entity.entity.States as PostState[]).find((state: PostState) => state.UUID === entity.entity.CurrentState)?.Content || ""
    }
    else {
      content = ""
    }
    let el = document.createElement("div");
    const fragment = htmlRenderer.convert(content);
    el.appendChild(fragment);
    return el.innerHTML
  })()

  if (activeTab === undefined) {
    return (<div className={styles.help}>Please search and select an entity or create a new one</div>)
  }

  if (!entity) {
    return <div className={styles.spinnerContainer}><Spinner /></div>;
  }

  // console.log('rerun');
  // console.log(ReactDOMServer.renderToString(<SourceReactContents />));

  return (
    <div className={styles.container}>
      <SplitPane
        primary="second"
        maxSize={350}
        size={200}
      >
        { preview ? (
          <div
          className={styles.preview}
          dangerouslySetInnerHTML={{__html: previewHtmlContents}}
          ></div>
        ) : (
          <div className={styles.sourceContainer} ref={sourceContainer} onScroll={handleSourceScroll} onInput={handleSourceScroll}>
            <p
              className={`${styles.source} source`}
              contentEditable="true"
              onInput={updateEntityBody}
              ref={editor}
              suppressContentEditableWarning
              // dangerouslySetInnerHTML={{__html: ReactDOMServer.renderToString(<SourceReactContents />)}}
              dangerouslySetInnerHTML={{__html: sourceHtmlContents}}
            ></p>
          </div>
        )}

      <div className={styles.infoPane}>
        { preview ? ( <InfoPanePreview entity={entity} /> ) : (<InfoPaneEdit entity={entity} />)}
      </div>
      </SplitPane>
    </div>
  );
}

export default Editor;

