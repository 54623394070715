import React, { useContext } from 'react';
import { useMutation } from 'react-query'

import { UserContext } from "../contexts/UserContext";
import { getToken } from '../apis/auth'
import logo from '../logo.png';
import Spinner from './Spinner';
import styles from './LoginBox.module.scss';

function LoginBox() {

  const mutation = useMutation(getToken)
  const { logIn } = useContext(UserContext);

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = e.currentTarget.email.value;
    const password = e.currentTarget.password.value;
    mutation.mutate({email, password}, {
      onSuccess: (data) => {
        logIn(email, data)
      }
    })
  }
  
  return (
    <div className={styles.loginBox}>
      <img src={logo} className={styles.logo} alt="logo" />
      <div className={styles.restrictionInfoBox}>
        <p className={styles.title}>Restricted Access</p>
        <p className={styles.content}>Only users who have been invited to join the private alpha would have an account.</p>
      </div>
  
      <form className={styles.loginForm} onSubmit={onSubmit} autoComplete="off" >
        <div className={styles.field}>
          <div className={styles.labelWrapper}>
            <label className={styles.label} htmlFor="email">Email</label>
          </div>
          <input className={styles.input} type="email" id="email" name="email" />
        </div>
        <div className={styles.field}>
          <div className={styles.labelWrapper}>
            <label className={styles.label} htmlFor="password">Password</label>
          </div>
          <input className={styles.input} type="password" id="password" name="password" />
        </div>
        {mutation.isError ? (
          <div className={styles.errorBox}>
            {mutation.error instanceof Error ? mutation.error.message : null }
          </div>
        ) : null}
        {mutation.isLoading ? (
          <button className={`${styles.button} disabled`} type="submit"><Spinner color="black" /></button>
        ) : (
          <button className={`${styles.button} primary`} type="submit">Enter</button>
        )}
      </form>
    </div>
  );
}

export default LoginBox;
