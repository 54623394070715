"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const linkPlugin = {
    name: "Link HTML Renderer",
    pluginType: "renderer",
    types: [
        mark_common_1.GMType.ExternalLink,
        mark_common_1.GMType.InternalLink,
    ],
    render: node => {
        if (node.children.length === 0) {
            return [[], undefined];
        }
        const a = document.createElement("a");
        a.classList.add('gmLink');
        a.classList.add(node.type === mark_common_1.GMType.ExternalLink ? 'gmExternalLink' : 'gmInternalLink');
        if (node.type === mark_common_1.GMType.ExternalLink) {
            a.setAttribute("target", "_blank");
            const linkContent = node.children[0].original;
            const [url, ...linkText] = linkContent.split(" ");
            a.setAttribute("href", url);
            const text = document.createTextNode(linkText.length > 0 ? linkText.join(" ") : url);
            a.appendChild(text);
        }
        else {
            const linkContent = node.children[0].original;
            const [uuid, ...linkText] = linkContent.split(" ");
            a.setAttribute("href", `https://learn.goji.press/${uuid}`);
            if (linkText.length > 0) {
                const text = document.createTextNode(linkText.join(" "));
                a.appendChild(text);
            }
            else {
                // TODO: Call the Goji API to get the title of the entity
                const text = document.createTextNode("TBC");
                a.appendChild(text);
            }
        }
        return [
            [{
                    element: a,
                }],
            undefined,
        ];
    }
};
exports.default = linkPlugin;
