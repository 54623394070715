import React from 'react';
import styles from './NameWithAliasInput.module.scss';

function NameWithAliasInput({ names = [""], onChange, onBlur}: { names?: string[], onChange: (name: string[]) => void, onBlur: (name: string) => void }) {

  const updateName = (index: number, name: string) => {
    if (names.length === 0) {
      onChange(names.concat(name));
    } else {
      onChange(names.map((oName, i) => i === index ? name : oName));
    }
  }
  const removeName = (index: number) => {
    onChange(names.filter((name, i) => i !== index));
  };
  const addName = () => {
    onChange(names.concat(''));
  }

  return (
    <div className={styles.container}>
      <div className={styles.name}>
        <label htmlFor="name">Name</label>
        <input
          className={styles.input}
          id="name"
          name="name"
          required
          value={names[0]}
          onChange={(e) => updateName(0, e.currentTarget.value)}
          onBlur={(e) => onBlur(e.currentTarget.value)}
        />
      </div>
      <div className={styles.alias}>
        <label>Alias(es)</label>
        { names.length > 1 ? names.slice(1).map((alias, i) => (
          <div className={styles.aliasContainer} key={i}>
            <input
              className={styles.input}
              name="alias"
              placeholder="Alias"
              value={alias}
              onChange={(e) => updateName(i + 1, e.currentTarget.value)}
            />
            <button className={styles.removeAliasButton} onClick={() => removeName(i + 1)}>-</button>
          </div>
        )) : null}
        <button className={styles.addAliasButton} onClick={() => addName()}>+</button>
      </div>
    </div>
  );
}

export default NameWithAliasInput;
