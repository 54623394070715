"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_parser_1 = require("@gojipress/mark-parser");
const mark_common_1 = require("@gojipress/mark-common");
class GMRenderer {
    constructor() {
        this.pluginMap = new Map();
        this.customPluginMap = {};
    }
    use(plugin) {
        if (plugin.pluginType === "renderer") {
            plugin.types.forEach(type => {
                this.pluginMap.set(type, plugin);
            });
        }
        if (plugin.pluginType === "custom") {
            this.customPluginMap[plugin.id] = plugin;
        }
    }
    render(node) {
        if (node.type !== mark_parser_1.GMType.Root) {
            throw new Error("The node passed to render must be of type GMType.Root");
        }
        const fragment = document.createDocumentFragment();
        const processedElements = [];
        let tempWrapper = null;
        for (let i = 0; i < node.children.length; i++) {
            const [renderedElements, wrapper, reuseWrapper] = node.children[i].type === mark_parser_1.GMType.Custom ? this.getCustomElements(node.children[i], [], node) : this.getRenderedElements(node.children[i], [], node, node.children[i - 1]);
            if (wrapper) {
                if (tempWrapper === null) {
                    tempWrapper = wrapper;
                    renderedElements.forEach((renderedElement) => {
                        tempWrapper.appendChild(renderedElement.element);
                    });
                }
                else {
                    if (reuseWrapper) {
                        renderedElements.forEach((renderedElement) => {
                            tempWrapper.appendChild(renderedElement.element);
                        });
                    }
                    else {
                        processedElements.push({ element: tempWrapper });
                        tempWrapper = null;
                        processedElements.push(...renderedElements);
                    }
                }
            }
            else {
                if (tempWrapper !== null) {
                    processedElements.push({ element: tempWrapper });
                    tempWrapper = null;
                }
                processedElements.push(...renderedElements);
            }
        }
        if (tempWrapper !== null) {
            processedElements.push({ element: tempWrapper });
            tempWrapper = null;
        }
        processedElements.forEach(renderedElement => {
            fragment.appendChild(renderedElement.element);
        });
        return fragment;
    }
    getCustomElements(node, indentContext, parentNode) {
        const [colons, pluginIdentifier] = mark_common_1.splitPrefix(node.original.opening, ":");
        const customPlugin = this.customPluginMap[pluginIdentifier];
        if (customPlugin) {
            return [[{
                        element: customPlugin.render((node.children[0] && node.children[0].original) || ""),
                    }]];
        }
        else {
            return this.getRenderedElements(node, indentContext, parentNode);
        }
    }
    getRenderedElements(node, indentContext, parentNode, prevNode) {
        const plugin = this.pluginMap.get(node.type);
        if (!plugin) {
            throw new Error(`No plugin has been loaded for the Node type ${node.type}`);
        }
        const [elements, wrapper] = plugin.render(node);
        if (node.children.length > 0) {
            const childrenContainer = elements.find(element => element.childrenContainer);
            if (childrenContainer) {
                const childIndentContext = node.indenter && typeof node.original === "string" ? [...indentContext, node.original.length] : [...indentContext];
                const processedElements = [];
                let tempWrapper = null;
                for (let i = 0; i < node.children.length; i++) {
                    const [renderedElements, wrapper, reuseWrapper] = node.children[i].type === mark_parser_1.GMType.Custom ? this.getCustomElements(node.children[i], childIndentContext, node) : this.getRenderedElements(node.children[i], childIndentContext, node, node.children[i - 1]);
                    if (node.indenter && plugin.indentChildren) {
                        if (i !== 0) {
                            childIndentContext.forEach(indent => {
                                const spaceSpan = document.createElement("span");
                                spaceSpan.classList.add('gmSHIndent');
                                const text = document.createTextNode(" ".repeat(indent));
                                spaceSpan.appendChild(text);
                                renderedElements.unshift({
                                    childrenContainer: false,
                                    element: spaceSpan,
                                });
                            });
                        }
                    }
                    else if (parentNode.indenter && plugin.indentChildren) {
                        childIndentContext.forEach(indent => {
                            const spaceSpan = document.createElement("span");
                            spaceSpan.classList.add('gmSHIndent');
                            const text = document.createTextNode(" ".repeat(indent));
                            spaceSpan.appendChild(text);
                            renderedElements.unshift({
                                childrenContainer: false,
                                element: spaceSpan,
                            });
                        });
                    }
                    // Deals with descendents of the indenter(s)
                    if (wrapper) {
                        if (tempWrapper === null) {
                            tempWrapper = wrapper;
                            renderedElements.forEach((renderedElement) => {
                                tempWrapper.appendChild(renderedElement.element);
                            });
                        }
                        else {
                            if (reuseWrapper) {
                                renderedElements.forEach((renderedElement) => {
                                    tempWrapper.appendChild(renderedElement.element);
                                });
                            }
                            else {
                                processedElements.push({ element: tempWrapper });
                                tempWrapper = null;
                                processedElements.push(...renderedElements);
                            }
                        }
                    }
                    else {
                        if (tempWrapper !== null) {
                            processedElements.push({ element: tempWrapper });
                            tempWrapper = null;
                        }
                        processedElements.push(...renderedElements);
                    }
                }
                if (tempWrapper !== null) {
                    processedElements.push({ element: tempWrapper });
                    tempWrapper = null;
                }
                processedElements.forEach(renderedElement => {
                    childrenContainer === null || childrenContainer === void 0 ? void 0 : childrenContainer.element.appendChild(renderedElement.element);
                });
            }
        }
        return [elements, wrapper, plugin.reuseWrapper ? plugin.reuseWrapper(prevNode) : false];
    }
}
exports.default = GMRenderer;
