import React from "react";
import { Concept } from "../../common/entities";
import ConceptSearcher from "./ConceptSearcher";

import styles from "./index.module.scss";

function ConceptsSelector({ concepts = [], label, onChange }: { concepts?: Concept[]; label: string; onChange: (name: Concept[]) => void }) {

  const addParent = (parent: Concept) => {
    if (!concepts || !Array.isArray(concepts)) {
      onChange([parent]);
      return
    }

    // Check for duplicates
    if (concepts.find(p => p.UUID === parent.UUID)) {
      return;
    }
    
    onChange(concepts.concat(parent));
  };

  const removeParent = (index: number) => {
    if (!Array.isArray(concepts)) {
      return;
    }
    onChange(concepts.filter((parent, i) => i !== index));
  };

  return (
    <div className={styles.container}>
      <label>{label}(s)</label>
      {Array.isArray(concepts) && concepts.length > 0 && concepts.map((parent, i) => (
        <div className={styles.parentsContainer} key={parent.UUID}>
          <input
            disabled={true}
            className={styles.input}
            name="concepts"
            value={parent.Names[0]}
          />
          <button className={styles.removeParentsButton} onClick={() => removeParent(i)} >-</button>
        </div>
      ))}
      <ConceptSearcher parents={concepts} label={label} onSelect={addParent} />
    </div>
  );
}

export default ConceptsSelector;
