import React, { createContext, FunctionComponent, useState } from "react";
import { SearchEntity } from '../common/entities'

export interface Tab extends SearchEntity {
  active: boolean
}

export interface Tabs extends Array<Tab> {}
export interface Panels extends Array<Tabs> { }

const maxPanels = 2

const TabsContext = createContext({
    panels: [[]] as Panels,
    activePanel: undefined as number | undefined,
    addPanel: (index: number) => {},
    removePanel: (index: number) => {},
    setActivePanel: (index: number) => {},
    setActiveTab: (panelIndex: number, tabIndex: number) => {},
    addTab: (tab: SearchEntity, panelIndex?: number, tabIndex?: number) => {},
    removeTab: (panelIndex: number, tabIndex: number) => {},
});

const TabsProvider: FunctionComponent = ({ children }) => {

    const [panels, setPanels] = useState([[]] as Panels)
    const [activePanel, setActivePanel] = useState(undefined as number | undefined);

    function insert<T>(a: Array<T>, b: T, i: number) {
      return a.slice(0, i).concat([b]).concat(a.slice(i))
    }

    function remove<T>(a: Array<T>, i: number) {
      return a.slice(0, i).concat(a.slice(i + 1))
    }
    
    const defaultTabsContext = {
      panels,
      activePanel,
      addPanel: (index?: number) => {
        if (maxPanels <= panels.length) {
          return
        }
        if (index === undefined || panels.length <= index) {
          index = panels.length - 1;
        }
        if (index < 0) {
          index = 0;
        }
        setPanels(insert(panels, [], index));
      },
      removePanel: (index: number) => {
        setPanels(remove(panels, index));
        setActivePanel(0);
      },
      setActivePanel: (index: number) => {
        setActivePanel(index);
      },
      setActiveTab: (panelIndex: number, tabIndex: number) => {
        const panel = panels[panelIndex];
        const newPanel = panel.map((tab, i) => {
          tab.active = i === tabIndex;
          return tab
        })
        setPanels(panels.slice(0, panelIndex).concat([newPanel]).concat(panels.slice(panelIndex + 1)));
      },
      addTab: (entity: SearchEntity, panelIndex: number = 0, tabIndex: number = 0) => {
        if (panels.length === 0) {
          setPanels([[{
            ...entity,
            active: true,
          }]]);
          return
        }

        // Check if there is already a tab in the same panel with the same UUID
        console.log(panelIndex)
        const panel = panels[panelIndex]
        const existingTab = panel.find(tab => tab.UUID === entity.UUID)
        if (existingTab) {
          defaultTabsContext.setActiveTab(panelIndex, panel.indexOf(existingTab))
          return
        }

        const tab: Tab = {
          ...entity,
          active: true,
        }
        const newPanel = insert(panel.map(tab => ({...tab, active: false})), tab, tabIndex)
        setPanels(panels.slice(0, panelIndex).concat([newPanel]).concat(panels.slice(panelIndex + 1)));
      },
      removeTab: (panelIndex: number, tabIndex: number) => {
        const panel = panels[panelIndex]
        const newPanel = remove(panel, tabIndex)

        // Make sure at least one tab is active
        if (0 < newPanel.length) {
          const activeTab = newPanel.find(tab => tab.active)
          if (!activeTab) {
            newPanel[0].active = true
          }
        }

        // Always leave a panel
        if (newPanel.length === 0 && 1 < panels.length) {
          setPanels(remove(panels, panelIndex))
        } else {
          setPanels(panels.slice(0, panelIndex).concat([newPanel]).concat(panels.slice(panelIndex + 1)));
        }
      },
    }
    return (
      <TabsContext.Provider value={defaultTabsContext}>
        {children}
      </TabsContext.Provider>
    )
}

export {
    TabsProvider as default,
    TabsContext,
    maxPanels,
};
