"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const videoPlugin = {
    name: "Video HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Video],
    render: node => {
        const video = document.createElement("video");
        video.classList.add('gmVideo');
        video.setAttribute("controls", "true");
        if (node.children.length > 0) {
            const source = document.createElement("source");
            const content = node.children[0].original;
            const [src] = content.split(" ");
            source.setAttribute("src", src);
            video.appendChild(source);
            const fallbackMessage = document.createElement("p");
            fallbackMessage.innerHTML = `Your browser doesn't support HTML5 video. Here is
      a <a href="${src}">link to the video</a> instead.`;
            video.appendChild(fallbackMessage);
            return [
                [{
                        element: video,
                    }],
                undefined,
            ];
        }
        return [[], undefined];
    }
};
exports.default = videoPlugin;
