import React from "react";
import { UseQueryResult } from "react-query";
import Spinner from './Spinner';
import SearchResultItem from './SearchResultItem';
import styles from './SearchResults.module.scss';
import { ISearchResultItem } from '../common/entities'

function SearchResults({results}: {results: UseQueryResult}) {
  const { status, data, error } = results;

  let inside

  if (status === "loading") {
    inside = <Spinner className={styles.spinner} />;
  }

  if (status === "error") {
    inside = error instanceof Error ? <span>Error: {error.message}</span> : null;
  }
  if (Array.isArray(data)) {
    inside = data.map((result: ISearchResultItem) => <SearchResultItem entity={result} key={result.Self.UUID} />)
  }

  return (
    <div className={styles.container}>
      {inside}
    </div>
  )
}

export default SearchResults;
