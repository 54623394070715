"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const headingPlugin = {
    name: "Heading HTML Renderer",
    pluginType: "renderer",
    types: [
        mark_common_1.GMType.Heading1,
        mark_common_1.GMType.Heading2,
        mark_common_1.GMType.Heading3,
        mark_common_1.GMType.Heading4,
        mark_common_1.GMType.Heading5,
        mark_common_1.GMType.Heading6,
    ],
    render: (node) => {
        let heading;
        switch (node.type) {
            case mark_common_1.GMType.Heading1:
                heading = document.createElement("h1");
                break;
            case mark_common_1.GMType.Heading2:
                heading = document.createElement("h2");
                break;
            case mark_common_1.GMType.Heading3:
                heading = document.createElement("h3");
                break;
            case mark_common_1.GMType.Heading4:
                heading = document.createElement("h4");
                break;
            case mark_common_1.GMType.Heading5:
                heading = document.createElement("h5");
                break;
            case mark_common_1.GMType.Heading6:
            default:
                heading = document.createElement("h6");
                break;
        }
        heading.classList.add(`gmHeading`);
        heading.classList.add(`gm${node.type}`);
        return [
            [{
                    element: heading,
                    childrenContainer: true,
                }],
            undefined,
        ];
    },
};
exports.default = headingPlugin;
