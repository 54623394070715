"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const unorderedListItemPlugin = {
    name: "Unordered List Item Block Parser",
    pluginType: "parser",
    kind: mark_common_1.GMKind.Block,
    dependencies: [],
    charProcessor: (char, state) => {
        if (char === "-" &&
            state.contextualLinePosition === 0) {
            state.nextRelevantPlugins.push(unorderedListItemPlugin);
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        if (mark_common_1.isWhiteSpaceChar(char) &&
            state.contextualLinePosition === 1 &&
            state.unattachedChars === "-") {
            state.availableIndentContext.push(2);
            return {
                nextPlugin: false,
                charCaptured: true,
                addition: {
                    type: mark_common_1.GMType.UnorderedListItem,
                    original: "- ",
                    indenter: true,
                    children: [],
                },
                newIndentContext: state.indentContext + 1,
            };
        }
        if (mark_common_1.isWhiteSpaceChar(char) &&
            state.linePosition < state.availableIndentContext.reduce((total, current) => total + current, 0) &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, mark_common_1.whitespaces)) {
            state.nextRelevantPlugins.push(unorderedListItemPlugin);
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        return {
            nextPlugin: true,
            charCaptured: false,
        };
    },
    EOFProcessor: () => ({ nextPlugin: true })
};
exports.default = unorderedListItemPlugin;
