"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const thematicBreakPlugin = {
    name: "Thematic Break Syntax Highlighter",
    pluginType: "renderer",
    types: [mark_common_1.GMType.ThematicBreak],
    render: node => {
        const span = document.createElement("span");
        span.classList.add('gmThematicBreak');
        const text = document.createTextNode(node.original);
        span.appendChild(text);
        return [
            [{
                    element: span
                }, {
                    element: document.createElement("br")
                }],
            undefined,
        ];
    }
};
exports.default = thematicBreakPlugin;
