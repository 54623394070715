"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const customPlugin = {
    name: "Custom Block Syntax Highlighter",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Custom],
    indentChildren: true,
    render: (node) => {
        const elements = [];
        if (node.original &&
            typeof node.original === "object" &&
            node.original.opening) {
            const [colons, pluginIdentifier] = mark_common_1.splitPrefix(node.original.opening, ":");
            const colonSpan = document.createElement("span");
            colonSpan.classList.add("gmCustomOpeningBackticks");
            const text = document.createTextNode(colons);
            colonSpan.appendChild(text);
            elements.push({
                element: colonSpan,
            });
            if (pluginIdentifier) {
                const pluginIdentifierSpan = document.createElement("span");
                pluginIdentifierSpan.classList.add("gmCustomOpeningPluginIdentifier");
                const text = document.createTextNode(pluginIdentifier);
                pluginIdentifierSpan.appendChild(text);
                elements.push({
                    element: pluginIdentifierSpan,
                });
            }
            elements.push({
                element: document.createElement("br"),
            });
        }
        const contentSpan = document.createElement("span");
        contentSpan.classList.add("gmCustomContent");
        elements.push({
            element: contentSpan,
            childrenContainer: true,
        });
        if (node.original &&
            typeof node.original === "object" &&
            node.original.closing) {
            const span = document.createElement("span");
            span.classList.add("gmCustomClosing");
            const text = document.createTextNode(node.original.closing);
            span.appendChild(text);
            elements.push({
                element: span,
            }, {
                element: document.createElement("br"),
            });
        }
        const wrapper = document.createElement("span");
        wrapper.classList.add("gmCustom");
        wrapper.setAttribute("spellcheck", "false");
        return [elements, wrapper];
    },
};
exports.default = customPlugin;
