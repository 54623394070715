import axios from "axios";

const search = (
  token: string,
  query: string = "",
  entityType: string[] = ["concept", "post"],
  privacy: string[] = ["self", "private", "public", "world"],
) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/search`, {
      params: {
        q: query,
        t: entityType.map(s => s.toLowerCase()),
        p: privacy,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to search");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    });
};

export {
  search
}