import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { search } from "../apis/entities";
import { UserContext } from "../contexts/UserContext";
import SearchResults from './SearchResults';
import styles from './SearchPane.module.scss';

function SearchPane() {
  const { userState } = useContext(UserContext);
  const [query, setQuery] = useState("");
  const results = useQuery(["entities", query], () =>
    search(userState.token, query)
  );
  return (
    <div className={styles.searchPane}>
      <input
        type="search"
        name="search"
        id="search"
        placeholder="Search"
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />
      <SearchResults results={results} />
    </div>
  );
}

export default SearchPane;
