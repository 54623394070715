"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const latexInlinePlugin = {
    name: "LaTeX Inline Syntax Highlighter",
    pluginType: "renderer",
    types: [mark_common_1.GMType.LatexInline],
    render: node => {
        const elements = [];
        if (node.original && typeof node.original === "object" && node.original.opening) {
            const span = document.createElement("span");
            span.classList.add('gmSHLatexInlineOpening');
            const text = document.createTextNode(node.original.opening);
            span.appendChild(text);
            elements.push({
                element: span,
            });
        }
        const contentSpan = document.createElement("span");
        contentSpan.classList.add('gmSHLatexInlineContent');
        contentSpan.setAttribute("spellcheck", "false");
        elements.push({
            element: contentSpan,
            childrenContainer: true,
        });
        if (node.original && typeof node.original === "object" && node.original.closing) {
            const span = document.createElement("span");
            span.classList.add('gmSHLatexInlineClosing');
            const text = document.createTextNode(node.original.closing);
            span.appendChild(text);
            elements.push({
                element: span,
            });
        }
        return [elements, undefined];
    }
};
exports.default = latexInlinePlugin;
