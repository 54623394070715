"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const types_1 = require("../../../types");
const mark_common_1 = require("@gojipress/mark-common");
const paragraphPlugin = {
    name: "Paragraph Parser",
    pluginType: "parser",
    kind: types_1.GMKind.Block,
    dependencies: [],
    charProcessor: (char, state, inlineParser) => {
        if (mark_common_1.isLineEndingChar(char) &&
            state.unattachedChars.length > 0 &&
            state.contextualLinePosition === state.unattachedChars.length &&
            !mark_common_1.stringOnlyContainsChars(state.unattachedChars, mark_common_1.whitespaces)) {
            return {
                nextPlugin: false,
                charCaptured: true,
                addition: {
                    type: types_1.GMType.Paragraph,
                    children: inlineParser(state.unattachedChars),
                },
            };
        }
        state.nextRelevantPlugins.push(paragraphPlugin);
        return {
            nextPlugin: true,
            charCaptured: false,
        };
    },
    EOFProcessor: (state, inlineParser) => {
        if (state.unattachedChars.length > 0 &&
            !mark_common_1.stringOnlyContainsChars(state.unattachedChars, mark_common_1.whitespaces)) {
            return {
                nextPlugin: false,
                addition: {
                    type: types_1.GMType.Paragraph,
                    children: inlineParser(state.unattachedChars),
                }
            };
        }
        return {
            nextPlugin: true,
        };
    },
};
exports.default = paragraphPlugin;
