import React, { useState } from 'react';
import ReactModal from 'react-modal';
import WorldMap from "../WorldMap";
import globeIcon from "./Globe.svg";
import styles from './index.module.scss';

function ShowWorldMapButton() {
  const [ open, setOpen ] = useState(false);
  return (
    <React.Fragment>
      <img src={globeIcon} alt="" className={styles.icon} onClick={() => setOpen(open => !open)} />
      <ReactModal
        isOpen={open}
        style={{ overlay: {}, content: {} }}
        overlayClassName={styles.overlay}
        className={styles.content}
        onRequestClose={() => setOpen(open => !open)}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        bodyOpenClassName={null}
      >
        <WorldMap />
      </ReactModal>
    </React.Fragment>
  );
}

export default ShowWorldMapButton;
