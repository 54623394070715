import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./MainMenuItem.module.scss";
import BookActiveIcon from "./IconBookActive.svg";
import BookInactiveIcon from "./IconBookInactive.svg";
import CogwheelActiveIcon from "./IconCogwheelActive.svg";
import CogwheelInactiveIcon from "./IconCogwheelInactive.svg";
import MagnifyingGlassActiveIcon from "./IconMagnifyingGlassActive.svg";
import MagnifyingGlassInactiveIcon from "./IconMagnifyingGlassInactive.svg";
import PenNibActiveIcon from "./IconPenNibActive.svg";
import PenNibInactiveIcon from "./IconPenNibInactive.svg";

function MainMenuItem({ link }: { link: string }) {
  let activeIcon, inactiveIcon;
  switch (link) {
    case "/search":
      activeIcon = MagnifyingGlassActiveIcon;
      inactiveIcon = MagnifyingGlassInactiveIcon;
      break;
    case "/publish":
      activeIcon = PenNibActiveIcon;
      inactiveIcon = PenNibInactiveIcon;
      break;
    case "/lists":
      activeIcon = BookActiveIcon;
      inactiveIcon = BookInactiveIcon;
      break;
    case "/settings":
    default:
      activeIcon = CogwheelActiveIcon;
      inactiveIcon = CogwheelInactiveIcon;
      break;
  }
  return (
    <div className={styles.mainMenuItem}>
      <NavLink to={link}>
        <div className={styles.mainMenuItemIcon}>
          <img src={activeIcon} alt="" className={styles.active} />
          <img src={inactiveIcon} alt="" className={styles.inactive} />
        </div>
      </NavLink>
    </div>
  );
}

export default MainMenuItem;
