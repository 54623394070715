import shajs from "sha.js";
import axios from "axios";

const register = (email: string, password: string) => {
  const hash = shajs("sha256").update(password).digest("hex");
  return axios
    .post(`${process.env.REACT_APP_AUTH_URL}/users`, { email, hash })
    .then((res) => {
      if (res.status === 202) {
        return true;
      }
      throw new Error();
    });
};

const getToken = ({email, password}: {email: string, password: string}): Promise<string> => {
  const hash = shajs("sha256").update(password).digest("hex");
  return axios
    .post(`${process.env.REACT_APP_AUTH_URL}/token`, { email, hash })
    .then((res) => res.data.token)
    .catch((err) => {
      if (err.response.status === 400) {
        throw new Error("Invalid credentials");
      }
    });
};

export { getToken, register };
