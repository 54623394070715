import React, { useContext, useState } from 'react';
import { useQuery } from "react-query";
import { UserContext } from "../../contexts/UserContext";
import { Concept } from "../../common/entities";
import { search } from "../../apis/entities"
import ConceptSearcherResults from './ConceptSearcherResults';
import styles from './ConceptSearcher.module.scss';

function ConceptSearcher({ parents, label, onSelect }: { parents: Concept[]; label: string; onSelect: (name: Concept) => void; }) {
  const { userState } = useContext(UserContext);
  const [query, setQuery] = useState("");
  const results = useQuery(["parents", query], () => {
    if (query !== "") {
      return search(userState.token, query, ["concept"])
    }
    return [];
  });
  const handleSelect = (name: Concept) => {
    setQuery("");
    onSelect(name);
  }
  return (
    <div className={styles.container}>
      <input
        placeholder={`Add ${label}`}
        type="search"
        value={query}
        onChange={(event) => setQuery(event.currentTarget.value)}
      />
      { query !== "" ? (<ConceptSearcherResults parents={parents} results={results} onSelect={handleSelect} />) : null }
    </div>
  );
}

export default ConceptSearcher;
