"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const mark_parser_1 = require("@gojipress/mark-parser");
const paragraphPlugin = {
    name: "Paragraph React Syntax Highlighter",
    pluginType: "react-renderer",
    types: [mark_parser_1.GMType.Paragraph],
    render: () => {
        return [
            [{
                    childrenContainer: true,
                    element: () => (jsx_runtime_1.jsx("span", { className: "gmParagraph" }, void 0)),
                }, {
                    element: () => jsx_runtime_1.jsx("br", {}, void 0),
                }],
            undefined
        ];
    }
};
exports.default = paragraphPlugin;
