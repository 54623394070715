"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const codeBlockPlugin = {
    name: "Code Block Syntax Highlighter",
    pluginType: "renderer",
    types: [mark_common_1.GMType.CodeBlock],
    indentChildren: true,
    reuseWrapper: () => false,
    render: (node) => {
        const elements = [];
        if (node.original &&
            typeof node.original === "object" &&
            node.original.opening) {
            const [backticks, infoString] = mark_common_1.splitPrefix(node.original.opening, "`");
            const backtickSpan = document.createElement("span");
            backtickSpan.classList.add("gmSHCodeBlockOpeningBackticks");
            const text = document.createTextNode(backticks);
            backtickSpan.appendChild(text);
            elements.push({
                element: backtickSpan,
            });
            if (infoString) {
                const infoStringSpan = document.createElement("span");
                infoStringSpan.classList.add("gmSHCodeBlockOpeningInfoString");
                const text = document.createTextNode(infoString);
                infoStringSpan.appendChild(text);
                elements.push({
                    element: infoStringSpan,
                });
            }
            elements.push({
                element: document.createElement("br"),
            });
        }
        const contentSpan = document.createElement("span");
        contentSpan.classList.add("gmSHCodeBlockContent");
        contentSpan.setAttribute("spellcheck", "false");
        elements.push({
            element: contentSpan,
            childrenContainer: true,
        });
        if (node.original &&
            typeof node.original === "object" &&
            node.original.closing) {
            const span = document.createElement("span");
            span.classList.add("gmSHCodeBlockClosing");
            const text = document.createTextNode(node.original.closing);
            span.appendChild(text);
            elements.push({
                element: span,
            }, {
                element: document.createElement("br"),
            });
        }
        return [elements, undefined];
    },
};
exports.default = codeBlockPlugin;
