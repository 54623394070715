import axios from "axios";

const ping = (serverType: string) => {
  const envVarName = `REACT_APP_${serverType.toUpperCase()}_URL`
  return axios
    .get(`${process.env[envVarName]}/ping`)
    .then((res) => {
      if (res.status === 200) {
        return;
      }
      throw new Error();
    });
};

export {
  ping,
}