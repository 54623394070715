import React from 'react';
import { useQuery } from 'react-query'
import { ping } from '../apis/ping'
import styles from './Pinger.module.scss';

function Pinger() {
  const { status: apiStatus } = useQuery(['ping', 'api'], () => ping('api'))
  const { status: authStatus } = useQuery(['ping', 'auth'], () => ping('auth'))
  let status;
  if (apiStatus === 'error' || authStatus === 'error') {
    status = 'error';
  } else if (apiStatus === 'loading' || authStatus === 'loading') {
    status = 'loading';
  } else if (apiStatus === 'success' && authStatus === 'success') {
    status = 'success';
  } else {
    status = 'idle';
  }
  return (
    <div className={styles.pinger}>
      <div className={`${styles.indicator} ${styles[status]}`}></div>
      <div className={styles.errorMessage}>
        {apiStatus === "error" ? "API unavailable" : null}
        {authStatus === "error" ? "Authentication service unavailable" : null}
      </div>
    </div>
  );
}

export default Pinger;
