"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const emphasisPlugin = {
    name: "Emphasis HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Emphasis],
    render: () => {
        const em = document.createElement("em");
        em.classList.add('gmEmphasis');
        return [
            [{
                    element: em,
                    childrenContainer: true,
                }],
            undefined,
        ];
    }
};
exports.default = emphasisPlugin;
