import React, { useContext } from "react";
import { TabsContext } from "../../contexts/TabsContext";
import { EntitiesContext } from "../../contexts/EntitiesContext";
import { ReactComponent as ConceptIcon } from "./Concept.svg";
import { ReactComponent as PostIcon } from "./Post.svg";
import { ReactComponent as GojiIcon } from "./Goji.svg";
import { ReactComponent as SeriesIcon } from "./Series.svg";
import { ReactComponent as CollectionIcon } from "./Collection.svg";
import styles from "./index.module.scss";
import { ConceptWithContext, PostWithContext } from "../../common/entities";

function Tab({
  panelIndex,
  tabIndex,
}: {
  panelIndex: number;
  tabIndex: number;
}) {
  const { panels, removeTab, setActiveTab } = useContext(TabsContext);
  const { entities } = useContext(EntitiesContext);
  const tab = panels[panelIndex][tabIndex];
  const tabIcon = () => {
    switch (tab.EntityType.toLowerCase()) {
      case "concept":
        return <ConceptIcon />;
      case "post":
        return <PostIcon />;
      case "goji":
        return <GojiIcon />;
      case "series":
        return <SeriesIcon />;
      case "collection":
        return <CollectionIcon />;
      default:
        return null;
    }
  };

  const tabName = () => {
    if (entities[tab.UUID] && entities[tab.UUID].entity && entities[tab.UUID].entity.Self) {
      if (entities[tab.UUID].entityType === "Concept") {
        return (entities[tab.UUID].entity as ConceptWithContext).Self.Names[0]
      }
      if (entities[tab.UUID].entityType === "Post") {
        return (entities[tab.UUID].entity as PostWithContext).Self.Title
      }
     }
    return tab.Name
  }
  return (
    <div
      className={`${styles.tab} ${tab.active ? styles.active : null}`}
      onClick={() => setActiveTab(panelIndex, tabIndex)}
    >
      <div className={styles.icon}>{tabIcon()}</div>
      <div className={styles.name}>{tabName()}</div>
      <div
        className={`${styles.close} ${
          entities[tab.UUID] && entities[tab.UUID].modifiedSinceSynced
            ? styles.modified
            : null
        } ${entities[tab.UUID] && entities[tab.UUID].local
          ? styles.local
          : null}`}
        onClick={(e) => {
          e.stopPropagation();
          removeTab(panelIndex, tabIndex);
        }}
      ></div>
    </div>
  );
}

export default Tab;
