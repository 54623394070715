import React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import SearchPane from "./SearchPane";
import styles from "./SideBar.module.scss";

function SideBar() {
  return (
    <div className={styles.sideBar}>
      <Switch>
        <Route path="/search">
          <SearchPane />
        </Route>
        <Route path="/publish">
          <div>
            <div>Publish</div>
          </div>
        </Route>
        <Route path="/lists">
          <div>
            <div>Lists</div>
          </div>
        </Route>
        <Route path="/settings">
          <div>
            <div>Settings</div>
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default SideBar;
