import React from "react";
import { ISearchResultItem, Concept } from '../../common/entities'
import styles from './ConceptSearcherResultsItem.module.scss';

function ConceptSearcherResultsItem({entity, onSelect}: {entity: ISearchResultItem, onSelect: (name: Concept) => void;}) {
  const concept: Concept = {
    UUID: entity.Self.UUID,
    Names: [entity.Self.Name], // Additional names are irrelevant
    Slug: "", // Slug is irrelevant
    Privacy: entity.Self.Privacy,
    Owner: entity.Self.Owner,
  }
  return (
    <div
      className={`${styles.container}`}
      onClick={() => onSelect(concept)}
    >
      {entity.Self.Name}
    </div>
  )
}

export default ConceptSearcherResultsItem;
