"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const headingPlugin = {
    name: "Heading Parser",
    pluginType: "parser",
    kind: mark_common_1.GMKind.Block,
    dependencies: [],
    charProcessor: (char, state, inlineParser) => {
        if (mark_common_1.isLineEndingChar(char) && state.unattachedChars.length > 0) {
            const [headingMarkup, ...rest] = state.unattachedChars.split(" ");
            if (mark_common_1.stringOnlyContainsChars(headingMarkup, ["#"])) {
                let type;
                switch (headingMarkup.length) {
                    case 1:
                        type = mark_common_1.GMType.Heading1;
                        break;
                    case 2:
                        type = mark_common_1.GMType.Heading2;
                        break;
                    case 3:
                        type = mark_common_1.GMType.Heading3;
                        break;
                    case 4:
                        type = mark_common_1.GMType.Heading4;
                        break;
                    case 5:
                        type = mark_common_1.GMType.Heading5;
                        break;
                    case 6:
                        type = mark_common_1.GMType.Heading6;
                        break;
                    default:
                        // Should never reach here
                        return {
                            nextPlugin: true,
                            charCaptured: false,
                        };
                }
                const originalText = rest.join(" ");
                return {
                    nextPlugin: false,
                    charCaptured: true,
                    addition: {
                        type,
                        children: inlineParser(originalText),
                    },
                };
            }
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        if (char === "#") {
            if (state.unattachedChars.length < 6 &&
                state.linePosition === state.unattachedChars.length &&
                mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["#"])) {
                state.nextRelevantPlugins.push(headingPlugin);
                return {
                    nextPlugin: true,
                    charCaptured: false,
                };
            }
        }
        if (char === " " &&
            state.unattachedChars.length > 0 &&
            state.unattachedChars.length <= 6 &&
            state.linePosition === state.unattachedChars.length &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["#"])) {
            state.nextRelevantPlugins = [headingPlugin];
            return {
                nextPlugin: false,
                charCaptured: false,
            };
        }
        if (state.relevantPlugins.length === 1) {
            state.nextRelevantPlugins = [headingPlugin];
        }
        return {
            nextPlugin: true,
            charCaptured: false,
        };
    },
    EOFProcessor: (state, inlineParser) => {
        if (state.relevantPlugins.length === 1 &&
            state.unattachedChars.length > 0) {
            const [headingMarkup, ...rest] = state.unattachedChars.split(" ");
            let type;
            switch (headingMarkup.length) {
                case 1:
                    type = mark_common_1.GMType.Heading1;
                    break;
                case 2:
                    type = mark_common_1.GMType.Heading2;
                    break;
                case 3:
                    type = mark_common_1.GMType.Heading3;
                    break;
                case 4:
                    type = mark_common_1.GMType.Heading4;
                    break;
                case 5:
                    type = mark_common_1.GMType.Heading5;
                    break;
                case 6:
                    type = mark_common_1.GMType.Heading6;
                    break;
                default:
                    return {
                        nextPlugin: true,
                    };
            }
            const originalText = rest.join(" ");
            return {
                nextPlugin: false,
                addition: {
                    type,
                    children: inlineParser(originalText),
                }
            };
        }
        return {
            nextPlugin: true,
        };
    },
};
exports.default = headingPlugin;
