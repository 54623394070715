import React from 'react';
import styles from './Spinner.module.scss';

function Spinner({ color = 'white', size = 13, className = ""}: { color?: string; size?: number; className?: string }) {
  return (
    <div className={`${styles.spinner} ${styles[color]} ${className}`}>
        <div style={{width: size, height: size}}></div>
    </div>
  );
}

export default Spinner;
