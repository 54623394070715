"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const codeSpanPlugin = {
    name: "Code Span HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.CodeSpan],
    render: () => {
        const code = document.createElement("code");
        code.classList.add('gmCodeSpan');
        return [
            [{
                    element: code,
                    childrenContainer: true,
                }],
            undefined,
        ];
    }
};
exports.default = codeSpanPlugin;
