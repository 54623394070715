"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const unorderedListItemPlugin = {
    name: "Unordered List Item Syntax Highlighter",
    pluginType: "renderer",
    types: [mark_common_1.GMType.UnorderedListItem],
    indentChildren: true,
    render: node => {
        const bulletSpan = document.createElement("span");
        bulletSpan.classList.add('gmUnorderedListItemBullet');
        const text = document.createTextNode(node.original);
        bulletSpan.appendChild(text);
        const contentSpan = document.createElement("span");
        contentSpan.classList.add('gmUnorderedListItemContent');
        return [
            [{
                    element: bulletSpan,
                }, {
                    element: contentSpan,
                    childrenContainer: true,
                }],
            undefined,
        ];
    }
};
exports.default = unorderedListItemPlugin;
