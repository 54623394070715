"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const imagePlugin = {
    name: "Image HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Image],
    render: node => {
        const img = document.createElement("img");
        img.classList.add('gmImage');
        if (node.children.length > 0) {
            const imageContent = node.children[0].original;
            const [url, ...altText] = imageContent.split(" ");
            img.setAttribute("src", url);
            img.setAttribute("alt", altText.join(" "));
            return [
                [{
                        element: img,
                    }],
                undefined,
            ];
        }
        return [[], undefined];
    }
};
exports.default = imagePlugin;
