"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const emphasisPlugin = {
    name: "Emphasis Parser",
    pluginType: "parser",
    kind: mark_common_1.GMKind.Inline,
    dependencies: [],
    charProcessor: (char, state, list) => {
        if (char === "_") {
            state.context.push({
                plugin: emphasisPlugin,
                closer: "_",
                list: [...list],
            });
            return {
                nextPlugin: false,
                charCaptured: true,
                newContext: true,
            };
        }
        return {
            nextPlugin: true,
            charCaptured: false,
            newContext: false,
        };
    },
    closeProcessor: (state, list) => {
        list.push({
            type: mark_common_1.GMType.Emphasis,
            original: {
                opening: "_",
                closing: "_",
            },
            children: [],
        });
    },
    EOFProcessor: () => true,
    openingDetector: char => char === "_",
    contextCloseProcessor: (state, currentList, parentList, closer) => {
        const currentListClone = [...currentList];
        const parentListClone = [...parentList];
        if (state.unattachedChars) {
            currentListClone.push({
                type: mark_common_1.GMType.Text,
                original: state.unattachedChars,
                children: [],
            });
        }
        parentListClone.push({
            type: mark_common_1.GMType.Emphasis,
            original: {
                opening: "_",
                closing: closer ? "_" : null,
            },
            children: [...currentListClone],
        });
        return parentListClone;
    },
};
exports.default = emphasisPlugin;
