"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.binsFilledWithRemainder = exports.splitPrefix = exports.setLinePosition = exports.fragmentWrapper = exports.stringOnlyContainsChars = exports.isAlphanumeric = exports.isWhiteSpaceChar = exports.isLineEndingChar = exports.whitespaces = exports.lineEndingChars = void 0;
const lineEndingChars = [
    10,
    13, // Carriage return
].map((codePoint) => String.fromCodePoint(codePoint));
exports.lineEndingChars = lineEndingChars;
const whitespaces = [
    9, 32, 160, 5760, 8192, 8193, 8194, 8195, 8196, 8197, 8198, 8199, 8200, 8201,
    8202, 8239, 8287, 12288,
].map((codePoint) => String.fromCodePoint(codePoint));
exports.whitespaces = whitespaces;
function isLineEndingChar(char) {
    return lineEndingChars.includes(char);
}
exports.isLineEndingChar = isLineEndingChar;
function isWhiteSpaceChar(char) {
    return whitespaces.includes(char);
}
exports.isWhiteSpaceChar = isWhiteSpaceChar;
function isAlphanumeric(char) {
    const codePoint = char.codePointAt(0);
    return ((48 <= codePoint && codePoint <= 57) || // Digits
        (65 <= codePoint && codePoint <= 90) || // UPPERCASE
        (97 <= codePoint && codePoint <= 122) // lowercase
    );
}
exports.isAlphanumeric = isAlphanumeric;
// stringOnlyContainsChars - test if a string only contains a one of the specified characters
// Alternative implementation for c.length = 1 - `for(const strchar of str)if(strchar!==c)return false;return true}`
// Benchmarks at https://jsben.ch/hkAb6
function stringOnlyContainsChars(str, c) {
    if (c.length === 0) {
        return true;
    }
    if (c.length === 1) {
        return !str.split(c[0]).some((strchar) => strchar);
    }
    for (const strchar of str) {
        if (!c.includes(strchar)) {
            return false;
        }
    }
    return true;
}
exports.stringOnlyContainsChars = stringOnlyContainsChars;
// documentFragment does not produce HTML according to chai-dom
// So the fragmentWrapper appends the fragment to a throwaway div
function fragmentWrapper(fragment) {
    const div = document.createElement('div');
    div.appendChild(fragment.cloneNode(true));
    return div;
}
exports.fragmentWrapper = fragmentWrapper;
function setLinePosition(state, char) {
    // If the previous character is a newline character
    // Reset the line position to 0
    // Otherwise, increment the counter
    if (isLineEndingChar(char)) {
        state.linePosition = 0;
    }
    else {
        state.linePosition++;
    }
}
exports.setLinePosition = setLinePosition;
// splitPrefix('aaaaaabcdef', 'a') -> ['aaaaaa', 'bcdef']
function splitPrefix(s, prefixChar) {
    let prefixEnded = false;
    let prefix = "";
    let rest = "";
    for (let char of s) {
        if (char === prefixChar && !prefixEnded) {
            prefix += char;
        }
        else {
            prefixEnded = true;
            rest += char;
        }
    }
    return [prefix, rest];
}
exports.splitPrefix = splitPrefix;
function binsFilledWithRemainder(bins, available) {
    let binsFilled = 0;
    let remainder = available;
    for (let i = 0; i < bins.length; i++) {
        if (bins[i] <= remainder) {
            remainder = remainder - bins[i];
            binsFilled++;
        }
        else {
            break;
        }
    }
    return [binsFilled, remainder];
}
exports.binsFilledWithRemainder = binsFilledWithRemainder;
