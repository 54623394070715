"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const linkPlugin = {
    name: "Link Parser",
    pluginType: "parser",
    kind: mark_common_1.GMKind.Inline,
    dependencies: [],
    charProcessor: (char, state, list) => {
        if (char === "[" &&
            (state.unattachedChars.length === 0 || state.unattachedChars.length === 1)) {
            state.nextRelevantPlugins.push(linkPlugin);
            return {
                nextPlugin: true,
                charCaptured: false,
                newContext: false,
            };
        }
        if (mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["["]) &&
            (state.unattachedChars.length === 1 || state.unattachedChars.length === 2)) {
            list.push({
                type: state.unattachedChars.length === 1 ? mark_common_1.GMType.ExternalLink : mark_common_1.GMType.InternalLink,
                literalChildren: true,
                original: {
                    opening: state.unattachedChars,
                    closing: null,
                },
                children: [],
            });
            state.literalContext = {
                plugin: linkPlugin,
                closer: "]".repeat(state.unattachedChars.length),
            };
            state.unattachedChars = "";
            return {
                nextPlugin: false,
                charCaptured: false,
                newContext: false,
            };
        }
        return {
            nextPlugin: true,
            charCaptured: false,
            newContext: false,
        };
    },
    closeProcessor: (state, list, proper) => {
        var _a;
        const content = state.unattachedChars
            .split(((_a = state.literalContext) === null || _a === void 0 ? void 0 : _a.closer) || "")
            .join("");
        if (proper) {
            list[list.length - 1].original.closing =
                state.literalContext.closer;
        }
        if (content !== "") {
            list[list.length - 1].children.push({
                type: mark_common_1.GMType.Text,
                original: content,
                children: [],
            });
        }
    },
    openingDetector: char => char === "[",
    EOFProcessor: (state, list) => {
        if (mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["["])) {
            list.push({
                type: mark_common_1.GMType.ExternalLink,
                literalChildren: true,
                original: {
                    opening: "[",
                    closing: null,
                },
                children: [],
            });
            return false;
        }
        if (mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["[["])) {
            list.push({
                type: mark_common_1.GMType.InternalLink,
                literalChildren: true,
                original: {
                    opening: "[[",
                    closing: null,
                },
                children: [],
            });
            return false;
        }
        return true;
    },
};
exports.default = linkPlugin;
