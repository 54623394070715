"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const imagePlugin = {
    name: "Image Parser",
    pluginType: "parser",
    kind: mark_common_1.GMKind.Inline,
    dependencies: [],
    charProcessor: (char, state, list) => {
        if (char === "{") {
            if (state.unattachedChars.length === 0 || state.unattachedChars.length === 1) {
                state.nextRelevantPlugins.push(imagePlugin);
            }
            return {
                nextPlugin: true,
                charCaptured: false,
                newContext: false,
            };
        }
        if (mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["{"]) &&
            state.unattachedChars.length === 2) {
            list.push({
                type: mark_common_1.GMType.Image,
                literalChildren: true,
                original: {
                    opening: "{{",
                    closing: null,
                },
                children: [],
            });
            state.literalContext = {
                plugin: imagePlugin,
                closer: "}}",
            };
            state.unattachedChars = "";
            return {
                nextPlugin: false,
                charCaptured: false,
                newContext: false,
            };
        }
        return {
            nextPlugin: true,
            charCaptured: false,
            newContext: false,
        };
    },
    closeProcessor: (state, list, proper) => {
        var _a;
        const content = state.unattachedChars
            .split(((_a = state.literalContext) === null || _a === void 0 ? void 0 : _a.closer) || "")
            .join("");
        if (proper) {
            list[list.length - 1].original.closing =
                state.literalContext.closer;
        }
        if (content !== "") {
            list[list.length - 1].children.push({
                type: mark_common_1.GMType.Text,
                original: content,
                children: [],
            });
        }
    },
    openingDetector: char => char === "{",
    EOFProcessor: (state, list) => {
        if (mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["{{"])) {
            list.push({
                type: mark_common_1.GMType.Image,
                literalChildren: true,
                original: {
                    opening: "{{",
                    closing: null,
                },
                children: [],
            });
            return false;
        }
        return true;
    },
};
exports.default = imagePlugin;
