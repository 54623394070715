"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const codeBlockPlugin = {
    name: "Code Block Syntax Highlighter",
    pluginType: "renderer",
    types: [mark_common_1.GMType.CodeBlock],
    reuseWrapper: () => false,
    render: () => {
        const wrapper = document.createElement("pre");
        wrapper.classList.add("gmCodeBlock");
        wrapper.setAttribute("spellcheck", "false");
        const code = document.createElement("code");
        return [
            [{
                    element: code,
                    childrenContainer: true,
                }],
            wrapper,
        ];
    },
};
exports.default = codeBlockPlugin;
