import React from 'react';
import styles from './index.module.scss';
import MainMenuItem from './MainMenuItem'

function MainMenu() {
  return (
    <nav className={styles.container}>
        <MainMenuItem link="/search" />
        <MainMenuItem link="/publish" />
        <MainMenuItem link="/lists" />
        <MainMenuItem link="/settings" />
    </nav>
  );
}

export default MainMenu;
