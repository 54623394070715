import React, { createContext, FunctionComponent, useState, useEffect, useContext } from "react";
import { useMutation } from "react-query";
import { getConcept, createConcept, updateConcept } from "../apis/concepts";
import { getPost, createPost, updatePost } from "../apis/posts";
import { ConceptWithContext, PostWithContext } from "../common/entities";
import { UserContext } from "./UserContext";
import { v4 as uuidv4 } from "uuid";
import hash from 'object-hash';

export interface Entity {
  entityType: "Concept" | "Post";
  entity: ConceptWithContext | PostWithContext;
  local: boolean; // Whether the entity exists in the remote database
  modifiedSinceSynced: boolean;
  wellformed: boolean;
}

const EntitiesContext = createContext({
  entities: {} as { [key: string]: Entity },
  downloadRemoteEntity: (entityType: string, uuid: string, force?: boolean) => {},
  updateLocalEntity: (entity: ConceptWithContext | PostWithContext) => {},
  createLocalEntity: (entityType: "Concept" | "Post", uuid?: string) => {},
  uploadLocalEntity: (uuid: string | undefined) => {},
  removeLocalEntity: (uuid: string) => {},
});

const backgroundSyncInterval = 30

const EntitiesProvider: FunctionComponent = ({ children }) => {
  const { userState } = useContext(UserContext);

  const [ syncCount, setSyncCount ] = useState(0);
  const [entities, setEntities] = useState({} as { [key: string]: Entity });

  const conceptMutation = useMutation(updateConcept)
  const postMutation = useMutation(updatePost)
  // const queryClient = useQueryClient()

  const updateRemoteEntity = (entity: Entity) => {
    if (!entity.modifiedSinceSynced || entity.local || !entity.wellformed) {
      return;
    }
    switch (entity.entityType) {
      case "Concept":
        // Sync concept
        console.log("syncing concept")
        const conceptHash = hash(entity.entity)
        conceptMutation.mutate({concept: (entity.entity as ConceptWithContext), token: userState.token}, {
          onSuccess: (data, variables, context) => {
            if (conceptHash === hash(entity.entity)) {
              setEntities((prevEntites) => ({
                ...prevEntites,
                [entity.entity.Self.UUID]: {
                  ...entity,
                  modifiedSinceSynced: false,
                },
              }));
            }
          },
          onError(error) {
            console.log(error);
          },
        })
        break;
      case "Post":
        // Sync concept
        console.log("syncing post")
        const postHash = hash(entity.entity)
        postMutation.mutate({post: (entity.entity as PostWithContext), token: userState.token}, {
          onSuccess: (data, variables, context) => {
            if (postHash === hash(entity.entity)) {
              setEntities((prevEntites) => ({
                ...prevEntites,
                [entity.entity.Self.UUID]: {
                  ...entity,
                  modifiedSinceSynced: false,
                },
              }));
            }
          },
          onError(error) {
            console.log(error);
          }
        })
        break;
    }
  }

  useEffect(() => {
    let incrementSyncCount = setInterval(() => setSyncCount(prevCount => prevCount + 1), backgroundSyncInterval * 1000);
    return () => {
      clearTimeout(incrementSyncCount);
    };
  }, [])
  // useEffect(() => {
  //     Object.values(entities).forEach((entity: Entity) => {
  //       updateRemoteEntity(entity)
  //     })
  // }, [syncCount])

  const makeBlankConceptWithContext = (uuid: string): ConceptWithContext => {
    const conceptTempStateUUID = uuidv4();
    return {
      Self: {
        UUID: uuid,
        Names: [""],
        Slug: "",
        Privacy: "private",
        Owner: userState.id,
      },
      CurrentState: conceptTempStateUUID,
      States: [
        {
          UUID: conceptTempStateUUID,
          Definition: "",
        },
      ],
      Parents: [],
    };
  };

  const makeBlankPostWithContext = (uuid: string): PostWithContext => {
    const postTempStateUUID = uuidv4();
    return {
      Self: {
        UUID: uuid,
        Title: "",
        Slug: "",
        Privacy: "private",
        Owner: userState.id,
        Goji: false,
      },
      CurrentState: postTempStateUUID,
      States: [
        {
          UUID: postTempStateUUID,
          Content: "",
        },
      ],
      Covers: [],
      Parents: [],
    };
  };

  const defaultEntitiesContext = {
    entities: entities,
    downloadRemoteEntity: (entityType: string, uuid: string, force: boolean = false) => {
      // If the entity already exists
      if (entities[uuid] && !force) {
        return;
      }

      let remoteCallPromise;
      if (entityType.toLowerCase() === "concept") {
        remoteCallPromise = getConcept(userState.token, uuid);
      } else {
        remoteCallPromise = getPost(userState.token, uuid);
      }
      remoteCallPromise.then(
        (remoteEntity: ConceptWithContext | PostWithContext) => {
          let newEntity: Entity;
          if (entityType.toLowerCase() === "concept") {
            newEntity = {
              entityType: "Concept",
              entity: remoteEntity as ConceptWithContext,
              local: false,
              modifiedSinceSynced: false,
              wellformed: true,
            };
          } else {
            newEntity = {
              entityType: "Post",
              entity: remoteEntity as PostWithContext,
              local: false,
              modifiedSinceSynced: false,
              wellformed: true,
            };
          }
          setEntities((prevEntites) => ({
            ...prevEntites,
            [uuid]: newEntity,
          }));
        }
      );
    },
    updateLocalEntity: (entity: ConceptWithContext | PostWithContext) => {
      const wellformed = () => {
        const slugWellFormed = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g.test(entity.Self.Slug)
        if (entity.Self.Slug !== "" && !slugWellFormed) {
          return false;
        }

        const entityType = entities[entity.Self.UUID].entityType

        if (entityType === "Concept") {
          const names = (entity as ConceptWithContext).Self.Names;
          const namesWellFormed = names.length > 0 && names.every(name => name !== "")
          if (!namesWellFormed) {
            return false;
          }
        }

        if (entityType === "Post") {
          const title = (entity as PostWithContext).Self.Title;
          const titleWellFormed = title !== ""
          if (!titleWellFormed) {
            return false;
          }
        }
        return true;
      };
      setEntities((prevEntites) => ({
        ...prevEntites,
        [entity.Self.UUID]: {
          entityType: prevEntites[entity.Self.UUID].entityType,
          entity,
          local: prevEntites[entity.Self.UUID].local,
          modifiedSinceSynced: true,
          wellformed: wellformed(),
          // lastSynced: entities[uuid].lastSynced,
          // outdated: entities[uuid].outdated,
          // empty: entities[uuid].empty,
          // syncing: entities[uuid].syncing,
          // error: entities[uuid].error,
          // type: entities[uuid].type,
        },
      }));
    },
    createLocalEntity: (entityType: "Concept" | "Post", uuid: string = uuidv4()) => {
      const localEntity = {
        entityType,
        entity:
          entityType === "Concept"
            ? makeBlankConceptWithContext(uuid)
            : makeBlankPostWithContext(uuid),
        local: true,
        modifiedSinceSynced: false,
        wellformed: false,
      };
      setEntities((prevEntites) => ({
        ...prevEntites,
        [uuid]: localEntity,
      }));
    },
    uploadLocalEntity: (uuid: string | undefined) => {
      if (uuid === undefined) {
        return;
      }
      if (entities[uuid].local) {
        let remoteCallPromise;
        if (entities[uuid].entityType === "Concept") {
          remoteCallPromise = createConcept({
            concept: entities[uuid].entity as ConceptWithContext,
            token: userState.token,
          });
        } else {
          remoteCallPromise = createPost({
            post: entities[uuid].entity as PostWithContext,
            token: userState.token
          });
        }
        remoteCallPromise.then(() =>
          defaultEntitiesContext.downloadRemoteEntity(entities[uuid].entityType, uuid)
        );
      } else {
        updateRemoteEntity(entities[uuid])
      }
    },
    removeLocalEntity: (uuid: string) => {
      const clone = { ...entities };
      delete clone[uuid];
      setEntities(clone);
    },
  };
  return (
    <EntitiesContext.Provider value={defaultEntitiesContext}>
      {children}
    </EntitiesContext.Provider>
  );
};

export { EntitiesProvider as default, EntitiesContext };
