"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const customPlugin = {
    name: "Custom HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Custom],
    render: () => [[], undefined],
};
exports.default = customPlugin;
