import axios from "axios";
import { ConceptWithContext } from '../common/entities'

const createConcept = ({concept, token}: {concept: ConceptWithContext, token: string}) => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/concepts`, {
          n: concept.Self.Names.filter(n => n), // Remove empty names
          p: concept.Parents ? concept.Parents.map(p => p.UUID) : [],
          d: concept.States.find(s => s.UUID === concept.CurrentState)?.Definition,
          pr: concept.Self.Privacy,
          s: concept.Self.Slug,
          id: concept.Self.UUID,
        },{
          headers: {
            'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          }
      })
      .then((res) => {
        if (res.status === 201) {
          return;
        }
        if (res.status === 403) {
          throw new Error("User does not have permission to create Concepts");
        }
        if (res.status === 500) {
          throw new Error("Server Error");
        }
        throw new Error();
      });
  };
  
const updateConcept = ({concept, token}: {concept: ConceptWithContext, token: string}) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}/concepts/${concept.Self.UUID}`, {
        n: concept.Self.Names.filter(n => n), // Remove empty names
        p: concept.Parents ? concept.Parents.map(p => p.UUID) : [],
        d: concept.States.find(s => s.UUID === concept.CurrentState)?.Definition,
        pr: concept.Self.Privacy,
        s: concept.Self.Slug,
        v: false,
        ts: Date.now(),
      },{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
    })
    .then((res) => {
      if (res.status === 200) {
        return;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to update Concepts");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    })
    .catch((err) => {
      throw err
    });
};

const deleteConcept = (uuid: string, token: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/concepts/${uuid}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((res) => {
      if (res.status === 200) {
        return;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to delete Concepts");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    });
};


const getConcept = (
  token: string,
  uuid: string,
) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/concepts/${uuid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to get concept");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    });
};

export {
  createConcept,
  updateConcept,
  deleteConcept,
  getConcept,
};
