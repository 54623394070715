"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const linkPlugin = {
    name: "Link Syntax Highlighter",
    pluginType: "renderer",
    types: [
        mark_common_1.GMType.ExternalLink,
        mark_common_1.GMType.InternalLink
    ],
    render: node => {
        const elements = [];
        if (node.original && typeof node.original === "object" && node.original.opening) {
            const span = document.createElement("span");
            span.classList.add('gmSHLinkOpening');
            span.classList.add(node.original.opening === "[" ? "gmSHExternalLinkOpening" : "gmSHInternalLinkOpening");
            const text = document.createTextNode(node.original.opening);
            span.appendChild(text);
            elements.push({
                element: span,
            });
        }
        const contentSpan = document.createElement("span");
        contentSpan.classList.add('gmSHLinkContent');
        contentSpan.setAttribute("spellcheck", "false");
        elements.push({
            element: contentSpan,
            childrenContainer: true,
        });
        if (node.original && typeof node.original === "object" && node.original.closing) {
            const span = document.createElement("span");
            span.classList.add('gmSHLinkClosing');
            span.classList.add(node.original.opening === "]" ? "gmSHExternalLinkClosing" : "gmSHInternalLinkClosing");
            const text = document.createTextNode(node.original.closing);
            span.appendChild(text);
            elements.push({
                element: span,
            });
        }
        return [elements, undefined];
    }
};
exports.default = linkPlugin;
