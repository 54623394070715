import React, { useContext, useRef, useCallback } from 'react';
import { useQuery } from 'react-query';
import * as THREE from 'three'
import ForceGraph3D from 'react-force-graph-3d';
import { UserContext } from "../../contexts/UserContext";
import { single } from "../../apis/maps"
import styles from './index.module.scss';

function ConceptMap({uuid, width, height, showNavInfo = false, label = true}: { uuid: string, width?: number, height?: number, showNavInfo?: boolean, label?: boolean }) {
  const { userState } = useContext(UserContext);
  const { isLoading, isError, data } = useQuery(['todos', uuid], () => single(userState.token, uuid), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  })
  const fgRef = useRef();

  const handleClick = useCallback(node => {
    // Aim at node from outside it
    const distance = 40;
    const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);

    // @ts-ignore
    fgRef.current.cameraPosition(
      { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
      node, // lookAt ({ x, y, z })
      1000  // ms transition duration
    );
  }, [fgRef]);

  if (isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error</span>
  }

  // if (Array.isArray(data.nodes) && data.nodes.length === 0) {
  //   return <div>No relations</div>
  // }
  return (
    <ForceGraph3D
      ref={fgRef}
      width={width}
      height={height}
      graphData={data}
      nodeId="UUID"
      linkSource="To" // Reverses the arrow so it points to what can be understood after understanding the current entity
      linkTarget="From"
      // @ts-ignore
      nodeLabel={(node) => label ? `<div style="padding:6px 8px;border:1px solid #FFFFFF40;background-color:black">${node.Name}</div>` : ""}
      enableNodeDrag={false}
      backgroundColor="#0D011C"
      onNodeClick={handleClick}
      linkColor="red"
      linkOpacity={0.2}
      linkWidth={2}
      linkDirectionalArrowColor={() => "#FFFFFF"}
      linkDirectionalArrowLength={5}
      linkDirectionalArrowRelPos={0.95}
      showNavInfo={showNavInfo}
      nodeThreeObject={(node: any) => {
        if (node.UUID === uuid) {
          return new THREE.Mesh(
            new THREE.SphereGeometry(6),
            new THREE.MeshNormalMaterial({})
          )
        }
        const getColor = (type: string) => {
          if (type === "Concept") {
            return "#38FBFD";
          }
          else if (type === "Post") {
            return "#fff";
          }
        }
        return new THREE.Mesh(
          new THREE.SphereGeometry(6),
          new THREE.MeshLambertMaterial({
            color: getColor(node.EntityType),
            transparent: false,
            opacity: 1
          })
        )}
      }
    />
  );
}

export default ConceptMap;
