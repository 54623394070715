import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';

import { ConceptWithContext, PostWithContext } from "../../common/entities";
import { createConcept } from "../../apis/concepts";
import { createPost } from "../../apis/posts";
import { UserContext } from "../../contexts/UserContext";
import { EntitiesContext } from "../../contexts/EntitiesContext";

import styles from './index.module.scss';

function UploadLocalEntityButton({ uuid }: { uuid: string }) {
  const [state, setState] = useState("idle")
  const { userState } = useContext(UserContext);
  const { entities, downloadRemoteEntity } = useContext(EntitiesContext);

  const createConceptMutation = useMutation(createConcept)
  const createPostMutation = useMutation(createPost)

  const uploadLocalEntity = () => {
    if (entities[uuid].entityType === "Concept") {
      createConceptMutation.mutate({
        concept: entities[uuid].entity as ConceptWithContext,
        token: userState.token,
      }, {
        onError: () => {
          setState("error");
        },
        onSuccess: () => {
          setState("success");
          downloadRemoteEntity(entities[uuid].entityType, uuid, true)
        },
      })
    }
    if (entities[uuid].entityType === "Post") {
      createPostMutation.mutate({
        post: entities[uuid].entity as PostWithContext,
        token: userState.token,
      }, {
        onError: () => {
          setState("error");
        },
        onSuccess: () => {
          setState("success");
          downloadRemoteEntity(entities[uuid].entityType, uuid, true)
        },
      })
    }
  }

  return (
    <button
      onClick={() => uploadLocalEntity()}
      disabled={!entities[uuid].wellformed}
      className={`${styles.button} ${styles[state]}`}
    >Upload</button>
  );
}

export default UploadLocalEntityButton;
