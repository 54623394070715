import axios from "axios";
import { PostWithContext } from '../common/entities'

const createPost = ({post, token}: {post: PostWithContext, token: string}) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/posts`,
      {
        t: post.Self.Title,
        p: post.Parents ? post.Parents.map(p => p.UUID) : [],
        cov: post.Covers ? post.Covers.map(p => p.UUID) : [],
        con: post.States.find(c => c.UUID === post.CurrentState)?.Content,
        pr: post.Self.Privacy,
        s: post.Self.Slug,
        g: post.Self.Goji,
        id: post.Self.UUID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (res.status === 201) {
        return;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to create Posts");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    });
};

const updatePost = ({post, token}: {post: PostWithContext, token: string}) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}/posts/${post.Self.UUID}`,
      {
        t: post.Self.Title,
        p: post.Parents ? post.Parents.map(p => p.UUID) : [],
        cov: post.Covers ? post.Covers.map(c => c.UUID) : [],
        con: post.States.find(s => s.UUID === post.CurrentState)?.Content,
        g: post.Self.Goji,
        pr: post.Self.Privacy,
        s: post.Self.Slug,
        ts: Date.now(),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        return;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to update Posts");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    });
};

const deletePost = (uuid: string, token: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/posts/${uuid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to delete Posts");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    });
};

const getPost = (token: string, uuid: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/${uuid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      if (res.status === 403) {
        throw new Error("User does not have permission to get post");
      }
      if (res.status === 500) {
        throw new Error("Server Error");
      }
      throw new Error();
    });
};

export { createPost, updatePost, deletePost, getPost };
