import React, { useContext, useState } from 'react';
import { useQuery } from "react-query";
import { UserContext } from "../../contexts/UserContext";
import { Post } from "../../common/entities";
import { search } from "../../apis/entities"
import PostSearcherResults from './PostSearcherResults';
import styles from './PostSearcher.module.scss';

function PostSearcher({ posts, label, onSelect }: { posts: Post[]; label: string; onSelect: (name: Post) => void; }) {
  const { userState } = useContext(UserContext);
  const [query, setQuery] = useState("");
  const results = useQuery(["posts", query], () => {
    if (query !== "") {
      return search(userState.token, query, ["post"])
    }
    return [];
  });

  const handleSelect = (name: Post) => {
    setQuery("");
    onSelect(name);
  }
  return (
    <div className={styles.container}>
      <input
        placeholder={`Add ${label}`}
        type="search"
        value={query}
        onChange={(event) => setQuery(event.currentTarget.value)}
      />
      { query !== "" ? (<PostSearcherResults posts={posts} results={results} onSelect={handleSelect} />) : null }
    </div>
  );
}

export default PostSearcher;
