"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const thematicBreakPlugin = {
    name: "Thematic Break Parser",
    pluginType: "parser",
    kind: mark_common_1.GMKind.Block,
    dependencies: [],
    charProcessor: (char, state) => {
        // If a newline charater is encountered and the entire line consist of only 3 or more `-` characters
        if (mark_common_1.isLineEndingChar(char) &&
            state.unattachedChars.length >= 3 &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["-"])) {
            return {
                nextPlugin: false,
                charCaptured: true,
                addition: {
                    type: mark_common_1.GMType.ThematicBreak,
                    original: state.unattachedChars,
                    children: [],
                },
            };
        }
        // A thematic break can only happen with the hyphen character
        if (char !== "-") {
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        // The first character in the line is `-`
        if (mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["-"]) &&
            state.linePosition === state.unattachedChars.length) {
            state.nextRelevantPlugins.push(thematicBreakPlugin);
            return {
                nextPlugin: true,
                charCaptured: false,
            };
        }
        return {
            nextPlugin: true,
            charCaptured: false,
        };
    },
    EOFProcessor: (state) => {
        if (state.unattachedChars.length >= 3 &&
            mark_common_1.stringOnlyContainsChars(state.unattachedChars, ["-"])) {
            return {
                nextPlugin: false,
                addition: {
                    type: mark_common_1.GMType.ThematicBreak,
                    original: state.unattachedChars,
                    children: [],
                }
            };
        }
        return {
            nextPlugin: true,
        };
    },
};
exports.default = thematicBreakPlugin;
