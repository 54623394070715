import React, { useContext } from 'react';
import { UserContext } from "../contexts/UserContext";
import styles from './StatusBar.module.scss';
import Clock from './Clock'
import Pinger from './Pinger'
import ShowWorldMapButton from './ShowWorldMapButton';

function StatusBar() {
  const { userState } = useContext(UserContext);
  return (
    <div className={styles.statusBar}>
      <div className={styles.left}>
        <div>{userState.email}</div>
        <ShowWorldMapButton />
      </div>
      <div className={styles.right}>
        <Pinger />
        <Clock />
      </div>
    </div>
  );
}

export default StatusBar;
