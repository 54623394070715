import React, { useContext, useRef, useCallback } from 'react';
import { useQuery } from 'react-query';
import * as THREE from 'three'
import ForceGraph3D from 'react-force-graph-3d';
import { UserContext } from "../../contexts/UserContext";
import { world } from "../../apis/maps"
import styles from './index.module.scss';

function WorldMap({ width, height, showNavInfo = false, label = true}: { width?: number, height?: number, showNavInfo?: boolean, label?: boolean }) {
  const { userState } = useContext(UserContext);
  const { isLoading, isError, data } = useQuery(['todos'], () => world(userState.token), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  })
  const fgRef = useRef();

  const handleClick = useCallback(node => {
    // Aim at node from outside it
    const distance = 40;
    const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);

    // @ts-ignore
    fgRef.current.cameraPosition(
      { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
      node, // lookAt ({ x, y, z })
      1000  // ms transition duration
    );
  }, [fgRef]);

  if (isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error</span>
  }
  return (
    <ForceGraph3D
      ref={fgRef}
      width={width}
      height={height}
      graphData={data}
      nodeId="UUID"
      linkSource="From"
      linkTarget="To"
      nodeLabel={label ? "Name" : ""}
      enableNodeDrag={false}
      backgroundColor="#0D011C"
      onNodeClick={handleClick}
      linkColor="red"
      linkOpacity={0.2}
      linkWidth={2}
      showNavInfo={showNavInfo}
      nodeThreeObject={(node: any) => {
        const getColor = (type: string) => {
          if (type === "Concept") {
            return "#38FBFD";
          }
          else if (type === "Post") {
            return "#fff";
          }
        }
        return new THREE.Mesh(
          new THREE.SphereGeometry(6),
          new THREE.MeshLambertMaterial({
            color: getColor(node.EntityType),
            transparent: false,
            opacity: 1
          })
        )}
      }
    />
  );
}

export default WorldMap;
