import React, { useContext } from "react";
import { TabsContext } from "../contexts/TabsContext";
import { ISearchResultItem } from '../common/entities'
import styles from './SearchResultItem.module.scss';
import { EntitiesContext } from "../contexts/EntitiesContext";

function SearchResultItem({entity}: {entity: ISearchResultItem}) {
  const { downloadRemoteEntity } = useContext(EntitiesContext);
  const { activePanel, addTab } = useContext(TabsContext);
  return (
    <div
      className={`${styles.container} ${styles[entity.Self.EntityType]}`}
      onClick={() => {
        downloadRemoteEntity(entity.Self.EntityType, entity.Self.UUID)
        addTab(entity.Self, activePanel)
      }}
    >
      {entity.Self.Name}
    </div>
  )
}

export default SearchResultItem;
