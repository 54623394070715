"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const mark_common_1 = require("@gojipress/mark-common");
const strongPlugin = {
    name: "Strong HTML Renderer",
    pluginType: "renderer",
    types: [mark_common_1.GMType.Strong],
    render: () => {
        const strong = document.createElement("strong");
        strong.classList.add('gmStrongContent');
        return [
            [{
                    element: strong,
                    childrenContainer: true,
                }],
            undefined,
        ];
    }
};
exports.default = strongPlugin;
