import React from "react";
import { Post } from "../../common/entities";
import PostSearcher from "./PostSearcher";

import styles from "./index.module.scss";

function PostsSelector({ posts = [], label, onChange }: { posts?: Post[]; label: string; onChange: (name: Post[]) => void }) {

  const addPost = (post: Post) => {
    if (!posts || !Array.isArray(posts)) {
      onChange([post]);
      return
    }

    // Check for duplicates
    if (posts.find(p => p.UUID === post.UUID)) {
      return;
    }
    
    onChange(posts.concat(post));
  };

  const removePost = (index: number) => {
    if (!Array.isArray(posts)) {
      return;
    }
    onChange(posts.filter((post, i) => i !== index));
  };

  return (
    <div className={styles.container}>
      <label>{label}(s)</label>
      {Array.isArray(posts) && posts.length > 0 && posts.map((post, i) => (
        <div className={styles.postsContainer}>
          <input
            key={post.UUID}
            disabled={true}
            className={styles.input}
            name="posts"
            value={post.Title}
          />
          <button className={styles.removePostsButton} onClick={() => removePost(i)} >-</button>
        </div>
      ))}
      <PostSearcher posts={posts} label={label} onSelect={addPost} />
    </div>
  );
}

export default PostsSelector;
