import React, { useContext } from "react";
import Tab from "../Tab";
import { TabsContext, maxPanels } from "../../contexts/TabsContext";
import AddTabButton from "../AddTabButton"
import AddPanelIcon from "./AddPanelIcon.svg"
import RemovePanelIcon from "./RemovePanelIcon.svg"
import styles from "./index.module.scss";

function TabsBar({i}: {i: number}) {
  const { panels, activePanel, addPanel, removePanel } = useContext(TabsContext);

  return (
    <div className={`${styles.tabsBar} ${activePanel === i ? styles.active : ""}`}>
      <div className={styles.tabsScroller}>
        <AddTabButton />
        {panels[i].map((tab, j) => (
          <Tab panelIndex={i} tabIndex={j} key={tab.UUID} />
        ))}
        {/* <button><img src="https://img.icons8.com/material/48/000000/add.png"/></button> */}
        {/* opens context menu to select entity type */}
        {/* menu item onClick={() => addTab(i)} */}
      </div>
      <div className={styles.activePanelMenu}>
      { panels.length < maxPanels ? ( <button className={styles.addPanelButton} onClick={(e: React.MouseEvent<HTMLButtonElement>) => {e.stopPropagation(); addPanel(i)}}><img src={AddPanelIcon} alt="Add Panel"/></button> ) : null }
      { panels.length > 1 ? ( <button className={styles.removePanelButton} onClick={(e: React.MouseEvent<HTMLButtonElement>) => {e.stopPropagation();  removePanel(i)}}><img src={RemovePanelIcon} alt="Remove Panel"/></button> ) : null }
      </div>
    </div>
  );
}

export default TabsBar;
