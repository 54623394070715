import React, { useEffect, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SplitPane from "react-split-pane";
import MainMenu from "./components/MainMenu";
import StatusBar from "./components/StatusBar";
import SideBar from "./components/SideBar";
import EditorsGroup from "./components/EditorsGroup";
import styles from "./Shell.module.scss";
import "./Shell.scss";

function Shell() {

  const handleUserKeyPress = useCallback((event: globalThis.KeyboardEvent) => {
    const { key, ctrlKey } = event;
    if (ctrlKey && (key === "f" || key === "F")){
      event.preventDefault();

      if (!document.fullscreenElement) {
        document.body.requestFullscreen({ navigationUI: "hide" }).catch(err => {
          console.log(err)
        });
      } else {
        document.exitFullscreen();
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
  return (
    <div className={styles.shell}>
      <Router>
        <div className={styles.main}>
          <MainMenu />
          <div className={styles.splitPaneContainer}>
            <SplitPane split="vertical" defaultSize={250} minSize={150} maxSize={350}>
              <SideBar />
              <EditorsGroup />
            </SplitPane>
          </div>
        </div>
        <StatusBar />
      </Router>
    </div>
  );
}

export default Shell;
