"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLinePosition = void 0;
const mark_common_1 = require("@gojipress/mark-common");
function setLinePosition(state, char) {
    // If the previous character is a newline character
    // Reset the line position to 0
    // Otherwise, increment the counter
    if (mark_common_1.isLineEndingChar(char)) {
        state.linePosition = 0;
    }
    else {
        state.linePosition++;
    }
}
exports.setLinePosition = setLinePosition;
